import React from "react";

function Reports() {

  return (
    <div>
        <h3>Reports</h3>
    </div>
  );
}

export default Reports;
