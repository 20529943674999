import { useState } from "react";
import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import styled from "styled-components";

const MainAppLayout = ({ isSidebar, setIsSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const MainContent = styled.div`
    margin-top: 60px;
    margin-left: ${isCollapsed ? "62px" : "270px"};
    padding: 10px;
    overflow-y: auto;
    height: calc(100vh - 70px);
  `;

  return (
    <div style={{ display: "flex", fontFamily: "Poppins, sans-serif" }}>
      <Sidebar
        isSidebar={isSidebar}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <main
        style={{
          backgroundColor: "#f4f8fd",
          width: "100%",
          height: "100%",
        }}
      >
        <Topbar isCollapsed={isCollapsed} />
        <MainContent>
          <Outlet />
        </MainContent>
      </main>
    </div>
  );
};

export default MainAppLayout;
