import React, { useState, useEffect } from 'react';
import { Box, IconButton, Alert, AlertTitle, Container, Divider, Modal, CircularProgress, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Danger from '../../assets/Images/Danger.svg';
import './Notifications.css';

function NotificationsPanel({ open, onClose, rfpId }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clickedIndices, setClickedIndices] = useState([]); // Array to store permanently black notifications

  useEffect(() => {
    const fetchNotifications = async () => {
      const storedData = JSON.parse(localStorage.getItem('userDetails'));
      const userId = storedData.userDetails.id;
      try {
        const response = await axios.get(
          `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/notification/getlist?rfpid=${rfpId}&userid=${userId}`
        );
        const data = response.data;
        setNotifications([
          { type: 'Conference Due', text: data.conference_notify },
          { type: 'RFP Questions Due', text: data.questionnaire_notify },
          { type: 'Proposal Due', text: data.submission_notify },
        ]);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleHoverOrClick = (index) => {
    // Add the index to clickedIndices if it’s not already there
    if (!clickedIndices.includes(index)) {
      setClickedIndices((prev) => [...prev, index]);
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="notifications-modal-title" aria-describedby="notifications-modal-description">
      <Container>
        <Box
          sx={{
            height: 550,
            overflowY: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: '10px'
          }}
          className="notificationBox"
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <Typography className="fontstyleeb fs-6 mb-1">RFP issuer</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider className="mb-3" sx={{ opacity: 0.6 }} />

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            notifications.map((notification, index) => (
              <React.Fragment key={index}>
                <Alert
                  severity="none"
                  icon={
                    clickedIndices.includes(index)
                      ? false
                      : <img src={Danger} alt="DangerImage" width="18px" height="18px" />
                  }
                  sx={{
                    padding: '8px 16px',
                    alignItems: 'center',
                    minHeight: '60px',
                    '& .MuiAlert-message': {
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                      justifyContent: 'space-between'
                    },
                    color: clickedIndices.includes(index) ? 'black' : 'red',
                    cursor: 'pointer'
                  }}
                  onMouseEnter={() => handleHoverOrClick(index)}
                  onClick={() => handleHoverOrClick(index)}
                >
                  <AlertTitle className="fontstyleet main_text" sx={{ marginBottom: 0, fontSize: '14px' }}>
                    {notification.text}
                  </AlertTitle>
                </Alert>
                {index < notifications.length - 1 && <Divider className="mt-1 mb-1" sx={{ opacity: 0.6 }} />}
              </React.Fragment>
            ))
          )}
        </Box>
      </Container>
    </Modal>
  );
}

export default NotificationsPanel;
