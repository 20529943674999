import React, { useState, useEffect} from 'react';
import { Box, Button, Alert, AlertTitle, Container, Typography, Badge, Divider, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Danger from '../../assets/Images/Danger.svg';
import './Alerts_Notification.css';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const NotificationsPanel = ({ open, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshError, setRefreshError] = useState(null);


  const refreshtoken = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    if (!storedData) return;

    const userId = storedData.userDetails.id;
    try {
      const data = { userid: userId };
      const response = await axios.post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/refreshtoken",
        JSON.stringify(data),
        { headers: { "Content-Type": "application/json" } }
      );

      if (response?.data?.status_code === 200) {
        const { userDetails, access_token } = response.data;
        const userData = { userDetails, access_token };
        localStorage.setItem("userDetails", JSON.stringify(userData));
        window?.location.reload();
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };

useEffect(()=>{
  const fetchData = async () => {
    try {
      setLoading(true);
      const storedData = JSON.parse(localStorage.getItem('userDetails'));
      const accessToken = storedData.access_token;

      const url = `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/notification/getallnotify?notify_type_id=${selectedTab}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setNotifications(response.data.notificationDetails);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.status === 401) {
        refreshtoken();
      }
    }
    finally {
      setLoading(false);
    }
  };

  fetchData();

},[selectedTab])



  const handleChange = (newValue) => {
    setSelectedTab(newValue);
  };



  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="notifications-modal-title"
      aria-describedby="notifications-modal-description"
    >
      <Container>
        <Box sx={{ height: 550, overflowY: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 2, borderRadius: '10px' }} className='notificationBox'>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <Typography className="fw-bold fs-6 mb-1">Notifications</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider className="mb-3" sx={{ opacity: 0.6 }} />

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

          {error && <Alert severity="error">{error}</Alert>}
          {refreshError && <Alert severity="error">{refreshError}</Alert>}

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 2 }}>
            {/* Always show buttons but conditionally show badges based on notifications */}
            <Badge color="error" variant="dot" invisible={notifications.length === 0} className='buttons'>
              <Button
                className="fw-bold text-capitalize textname"
                variant={selectedTab === 1 ? 'contained' : 'outlined'}
                sx={{
                  color: selectedTab === 1 ? 'white' : 'rgb(1,117,255)',
                  backgroundColor: selectedTab === 1 ? 'rgb(1,117,255)' : 'transparent',
                  borderColor: 'rgb(1,117,255)',
                  '&:hover': {
                    backgroundColor: selectedTab === 1 ? 'rgb(1,117,255)' : 'rgba(1,117,255,0.1)',
                    borderColor: 'rgb(1,117,255)',
                  },
                }}
                onClick={() => handleChange(1)}
              >
                Conference Due
              </Button>
            </Badge>
            <Badge color="error" variant="dot" invisible={notifications.length === 0} className='buttons'>
              <Button
                className="fw-bold textname"
                variant={selectedTab === 2 ? 'contained' : 'outlined'}
                color="primary"
                sx={{
                  color: selectedTab === 2 ? 'white' : 'rgb(1,117,255)',
                  backgroundColor: selectedTab === 2 ? 'rgb(1,117,255)' : 'transparent',
                  borderColor: 'rgb(1,117,255)',
                  '&:hover': {
                    backgroundColor: selectedTab === 2 ? 'rgb(1,117,255)' : 'rgba(1,117,255,0.1)',
                    borderColor: 'rgb(1,117,255)',
                  },
                }}
                onClick={() => handleChange(2)}
              >
                RFP Questions Due
              </Button>
            </Badge>
            <Badge color="error" variant="dot" invisible={notifications.length === 0} className='buttons'>
              <Button
                className="fw-bold textname"
                variant={selectedTab === 3 ? 'contained' : 'outlined'}
                color="primary"
                sx={{
                  color: selectedTab === 3 ? 'white' : 'rgb(1,117,255)',
                  backgroundColor: selectedTab === 3 ? 'rgb(1,117,255)' : 'transparent',
                  borderColor: 'rgb(1,117,255)',
                  '&:hover': {
                    backgroundColor: selectedTab === 3 ? 'rgb(1,117,255)' : 'rgba(1,117,255,0.1)',
                    borderColor: 'rgb(1,117,255)',
                  },
                }}
                onClick={() => handleChange(3)}
              >
                Proposal Due
              </Button>
            </Badge>
          </Box>

          {notifications.length > 0 ? (
            notifications.map((not, index) => (
              <React.Fragment key={index}>
                <Alert
                  severity="none"
                  icon={<img src={Danger} alt="DangerImage" width="18px" height="18px" />}
                  sx={{
                    padding: '8px 16px',
                    alignItems: 'center',
                    minHeight: '60px',
                    '& .MuiAlert-message': {
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                      justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                      <AlertTitle className="main_text" sx={{ marginBottom: 0, fontSize: '14px', color: not?.status === false ? "red" : "#000" }}>{not?.title}</AlertTitle>
                      <Typography variant="body2" className='sub_text' sx={{ fontSize: '12px', color: 'text.secondary' }}>{not?.message}</Typography>
                    </div>
                    <div className="text-end">
                      <Typography variant="caption" sx={{ fontSize: '10px', color: 'text.secondary', paddingLeft: '27px' }}>{not?.date}</Typography>
                    </div>
                  </div>
                </Alert>
                {index < notifications.length - 1 && <Divider className='mb-1' />}
              </React.Fragment>
            ))
          ) : (
            <Typography>No notifications available.</Typography>
          )}
        </Box>
      </Container>
    </Modal>
  );
};

export default NotificationsPanel;
