import { useState } from "react";
import { Menu, MenuItem, SubMenu, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, Divider, IconButton, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import styled from "styled-components";
import DashboardIcon from '../assets/Images/DashboardIcon.svg';
import Reports from '../assets/Images/ReportsIcon.svg';
import VisualReportsIcon from '../assets/Images/VisualReportsIcon.svg';
import DataReportsIcon from '../assets/Images/DataReports.svg';
import ContentLibraryIcon from '../assets/Images/ContentLibraryIcon.svg';
import PromptsIcon from '../assets/Images/PromptsIcon.svg';
import SelectedDashboardIcon from '../assets/Images/SelectedDashboardIcon.svg';
// import SelectedReportsIcon from './SelectedReporsIcon.svg';
import SelectedVisualReportsIcon from '../assets/Images/SelectedVisualReportsIcon.svg';
import SelectedDataReportsIcon from '../assets/Images/SelectedDataReportsIcon.svg';
import SelectedContentLibraryIcon from '../assets/Images/SelectedContentLibraryIcon.svg';
import SelectedPromptIcon from '../assets/Images/SelectedPromptsIcon.svg';
import MITLOGO from '../assets/Images/MIT_logosvg.svg';
import './Sidebar.css'

const Container = styled.div`
  height: 100vh;
`;

const iconMappings = {
  "Dashboard": {
    default: DashboardIcon,
    selected: SelectedDashboardIcon,
  },
  "Reports": {
    default: Reports,
    selected: Reports,
  },
  "Visual Reports": {
    default: VisualReportsIcon,
    selected: SelectedVisualReportsIcon,
  },
  "Data Reports": {
    default: DataReportsIcon,
    selected: SelectedDataReportsIcon,
  },
  "Content Library": {
    default: ContentLibraryIcon,
    selected: SelectedContentLibraryIcon,
  },
  "Prompts": {
    default: PromptsIcon,
    selected: SelectedPromptIcon,
  }
};

const Item = ({ title, to, selected, setSelected, children, isOpen, setIsOpen, setItemSelected,imgStyle,isCollapsed }) => {
  const handleSubmenuClick = () => {
    setIsOpen(isOpen === title ? null : title);
  };

  const isSelected = selected === title;

  const iconMapping = iconMappings[title] || { default: '', selected: '' };
  const iconSrc = isSelected ? iconMapping.selected : iconMapping.default;

  const iconBackgroundColor = isSelected ? "transparent" : "rgba(215, 233, 245)";
  const textColor = isSelected ? "#fff" : "#000";


  

  if (children) {
    return (
      <SubMenu
      title={!isCollapsed ? (
        <Typography sx={{ fontWeight: 500, fontSize: '14px', color: textColor }}>
          {title}
        </Typography>
      ) : null}
        icon={<img src={iconSrc} alt={title} style={{ width: 20, height: 20 }} />}
        open={isOpen === title}
        onOpenChange={handleSubmenuClick}
        
      >
        {children}
      </SubMenu>
    );
  }

  return (
    <MenuItem
      active={isSelected}
      style={{
        backgroundColor: isSelected ? "#0175ff" : "transparent",
        borderRadius: 10,
        color: textColor,
        fontWeight: isSelected ? "bold" : "normal",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: isCollapsed?'50px':'230px',
         marginLeft: isCollapsed ? '4px' : '10px'
      }}
      onClick={() => {
        setSelected(title);
        setItemSelected(true);
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ backgroundColor: iconBackgroundColor, padding: 8, borderRadius: '10px', marginRight: 10 }} className="imagespan">
          <img src={iconSrc} alt={title} style={imgStyle} className="images" />
        </span>
        {!isCollapsed && (
          <Typography sx={{ fontWeight: 500, fontSize: '14px', color: textColor }}>
            {title}
          </Typography>
        )}
      </Box>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ isSidebar, isCollapsed, setIsCollapsed }) => {
  // const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [isOpen, setIsOpen] = useState(null);
  const [itemSelected, setItemSelected] = useState(false);

  const isMobileView = useMediaQuery('(max-width:600px)');

  const imgStyle = {
    width: isCollapsed ? '17px' : '20px',  // Adjust size based on collapse state
    height: isCollapsed ? '17px' : '20px', // Adjust size based on collapse state
    transition: 'width 0.3s ease, height 0.3s ease', // Smooth transition effect
    // marginRight:isCollapsed ?'25px':'0px'
  };

  return (
    <Container>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: isCollapsed ? '62px' : '250px',
          backgroundColor: '#fff',
          zIndex: 1000,
          boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
          "& .pro-sidebar-inner": {
            background: `#fff !important`,
          },
          "& .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper": {
            backgroundColor: 'rgba(215, 233, 245)'
          },
          "& .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper": {
            borderRadius: '10px',
            marginLeft:isCollapsed?'4px': '10px'
          },
          "& .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper": {
            display: 'none'
          },
          "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item":{
            padding: isCollapsed ? '8px 34px 8px 10px' : '8px 35px 8px 20px' 
          },
          "& .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item  ":{
            paddingLeft:isCollapsed?"0px":"24px"
          },
          "& .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item":{
            padding:isCollapsed?"8px 30px 8px 10px":"8px 30px 8px 15px"
          }
          
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            <MenuItem style={{ color: "#0175ff" }}>
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <img
                    src={MITLOGO}
                    style={{
                      height: 50, // Adjusted height
                      width: 180, // Adjusted width
                      position: 'relative',
                      top: '0',
                      cursor: 'auto'
                    }}
                    alt="LOGO"
                  />
                  {isMobileView && (
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              )}
              {isCollapsed && (
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              )}
              <Divider sx={{ borderColor: '#0D6EFD', paddingTop: '10px' }} />
            </MenuItem>

            <Box>
              <Item
                title="Dashboard"
                to="/dashboard"
                selected={selected}
                setSelected={setSelected}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setItemSelected={setItemSelected}
                imgStyle={imgStyle}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Reports"
                to='/d'
                selected={selected}
                setSelected={setSelected}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setItemSelected={setItemSelected}
                imgStyle={imgStyle}
                isCollapsed={isCollapsed}
              >
                <Item
                  title="Visual Reports"
                  to="/visual-reports"
                  selected={selected}
                  setSelected={setSelected}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setItemSelected={setItemSelected}
                  imgStyle={imgStyle}
                  isCollapsed={isCollapsed}
                 
                />
                <Item
                  title="Data Reports"
                  to="/data-reports"
                  selected={selected}
                  setSelected={setSelected}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setItemSelected={setItemSelected}
                  imgStyle={imgStyle}
                  isCollapsed={isCollapsed}
                />
              </Item>
              <Item
                title="Content Library"
                to="/content-library"
                selected={selected}
                setSelected={setSelected}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setItemSelected={setItemSelected}
                imgStyle={imgStyle}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Prompts"
                to="/prompts"
                selected={selected}
                setSelected={setSelected}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setItemSelected={setItemSelected}
                  imgStyle={imgStyle}
                  isCollapsed={isCollapsed}
              />
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
    </Container>
  );
};

export default Sidebar;