import React, { useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import NotificationsPanel from '../pages/Alerts_Notifications/Alerts_Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MANICON from '../assets/Images/MANLOGO.png';
import Robot from '../assets/Images/ROBOICON.png';
import More from '../assets/Images/More.png';
import Settings from '../assets/Images/Settings.svg';
import Logout from '../assets/Images/Logout.svg';
import Danger from '../assets/Images/Danger.svg';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import axios from 'axios';
import './Topbar.css';

const NotificationButton = styled(IconButton)({
  position: 'relative',
  padding: '10px',
});

const BadgeImage = styled('img')({
  position: 'absolute',
  top: 6,
  right: 8,
  width: '15px',
  height: '15px',
  borderRadius: '50%',
  backgroundColor: 'white', // Optional, to give it a background
});

const Topbar = ({ isCollapsed }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const storedData = JSON.parse(localStorage.getItem('userDetails'));
  const userName = storedData.userDetails.userName;
  const userRole = storedData.userDetails.userRole;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseicon = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    console.log('Profile clicked');
    handleClose();
  };

  const handleLogout = () => {
    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    if (storedData && storedData.userDetails) {
      const userId = storedData.userDetails.id;
      const accessToken = storedData.access_token;
      if (userId && accessToken) {
        axios.post(
          'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/logout',
          { userid: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem('userDetails');
              localStorage.removeItem('AlertPopupResponse');
              console.log('Logout successful');
              navigate('/');
            } else {
              console.error('Logout failed');
            }
          })
          .catch((error) => {
            console.error('Error during logout:', error);
          });
      } else {
        console.error('User ID or access token not found in userDetails');
      }
    } else {
      console.error('No userDetails found in localStorage');
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      backgroundColor={"#f4f8fd"}
      className='main_topbar'
      sx={{
        position: 'fixed',
        top: 0,
        left: isCollapsed ? '62px' : '270px', // Matches the sidebar width
        right: 0,
        height: '70px', // Fixed height
        backgroundColor: '#f4f8fd', // Background color
        zIndex: 1000, // Ensures it's on top of the main content
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '13px',
        paddingRight: '20px',
        alignItems: 'flex-start',
      }}
    >
      <Box className='responsiveBox align-item-center mt-2' >
        <div>
          <Typography className='fontstyleeb welcomeTo'>Welcome To,</Typography>
          <Typography sx={{ fontSize: '25px', fontWeight: 'bold' }} className='bidSmartText'>MIT BidSmart</Typography>
        </div>
        <div class="robotImageclass">
          <img src={Robot} alt='robotImage' className='robotImage' />
        </div>
      </Box>

      {/* ICONS */}
      <Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" px={0}>
          <NotificationButton onClick={handleOpen}>
            {notifications.length > 0 && (
              <BadgeImage src={Danger} alt="danger" className='notification_dangerIcon' />
            )}
            <CircleNotificationsIcon sx={{ color: '#0175FF', width: 35, height: 35 }} className='dangerIcon' />
          </NotificationButton>
          <Box display="flex" alignItems="center">
            <Box px={1}>
              <img style={{ width: 33, height: 33 }} src={MANICON} alt='MANICON' className='manIcon' />
            </Box>
            <Box px={0}>
              <Typography className='fontstyleeb userName' sx={{ fontSize: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>{userName}</Typography>
              <Typography sx={{ fontSize: '12px', color: '#565656', marginTop: '-3px', textTransform: 'uppercase' }} className='userRoleText'>
                {userRole.toLowerCase() === 'executive_person' ? 'executive' : userRole}
              </Typography>
            </Box>

            <Box sx={{ width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center' }} px={0} >
              <IconButton onClick={handleClick} className='moreicon'>
                <img src={More} alt='moreicon' className='moreIcon' style={{ width: '100%', height: '100%' }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseicon}
                PaperProps={{
                  sx: {
                    minWidth: 120, // Set the minimum width for the Menu
                  }
                }}
              >
                <MenuItem sx={{ color: '#667384' }} className='fontstylee' onClick={handleProfile}>
                  <img className='me-2' src={Settings} alt='settings' />
                  Settings
                </MenuItem>
                <MenuItem sx={{ color: '#667384' }} className='fontstylee' onClick={handleLogout}>
                  <img className='me-2' src={Logout} alt='logout' />
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="notifications-modal-title"
            aria-describedby="notifications-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 700,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '10px',
              }}
            >
              <NotificationsPanel open={open} onClose={handleClose}/>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
