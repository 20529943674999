import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Drawer,
  Divider,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { Save, Delete } from "@mui/icons-material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useSnackbar } from "../SnackbarContext";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import "./Prompts.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 4,
};

function PromptConfiguration() {
  const [searchTerm, setSearchTerm] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [editTagId, setEditTagId] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [editTagValue, setEditTagValue] = useState("");
  const [editQuestionValue, setEditQuestionValue] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState(null);
  const [newPrompt, setNewPrompt] = useState({
    tagName: "",
    prompt_description: "",
  });
  const { showSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({ tagName: "", prompt_description: "" });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const refreshtoken = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const userId = storedData.userDetails.id;
    try {
      const data = {
        userid: userId,
      };
      const response = await axios({
        method: "POST",
        url: "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/refreshtoken",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.status_code === 200) {
        const { userDetails, access_token } = response.data;

        const userData = {
          userDetails,
          access_token,
        };
        localStorage.setItem("userDetails", JSON.stringify(userData));
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPrompts = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;

    const search = "";
    const page = 0;
    const pageSize = 10;
    const url = `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/prompts_list?search=${encodeURIComponent(search)}&page=${page}&pageSize=${pageSize}`;

    setLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          setPrompts(response.data.data);
          console.log(response.data.data, "promptsData");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          refreshtoken();
          {
            console.error("Error fetching prompts:", error);
          }
        } else {
          console.error("Error fetching prompts:", error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  const validateForm = () => {
    let isValid = true;
    let errors = { tagName: "", prompt_description: "" };

    // Validate Tag Name
    if (!newPrompt.tagName.trim()) {
      errors.tagName = "Tag Name is required";
      isValid = false;
    } else if (newPrompt.tagName.length < 4 || newPrompt.tagName.length > 30) {
      errors.tagName = "Tag Name must be between 4 and 30 characters";
      isValid = false;
    }

    // Validate Question
    if (!newPrompt.prompt_description.trim()) {
      errors.prompt_description = "Question is required";
      isValid = false;
    } else if (newPrompt.prompt_description.length < 10) {
      errors.prompt_description = "Question must be at least 10 characters";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleDeletePrompt = async (id) => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;

    const url =
      "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/delprompt";

    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: { id },
      });

      if (response.data.status_code === 200) {
        setPrompts(prompts.filter((prompt) => prompt.id !== id));
        setDeleteConfirmOpen(false);
        showSnackbar("Prompt deleted successfully.");
      } else {
        console.error("Failed to delete prompt:", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        console.error(
          "Error:",
          error.response.data.msg || error.response.data.message
        );
      } else {
        console.error("Error deleting prompt:", error);
      }
    }
  };

  const handleEditTagClick = (prompt) => {
    setEditTagId(prompt.id);
    setEditTagValue(prompt.prompt_name);
  };

  const handleEditQuestionClick = (prompt) => {
    setIsEditing(true);
    setEditQuestionId(prompt.id);
    setEditQuestionValue(prompt.prompt_description);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSaveTagClick = (id) => {
    setIsEditing(true);

    setPrompts(
      prompts.map((prompt) =>
        prompt.id === id ? { ...prompt, prompt_name: editTagValue } : prompt
      )
    );
    setEditTagId(null);
  };

  const handleSaveQuestionClick = (id) => {
    setIsEditing(true);

    setPrompts(
      prompts.map((prompt) =>
        prompt.id === id
          ? { ...prompt, prompt_description: editQuestionValue }
          : prompt
      )
    );
    setEditQuestionId(null);
  };

  const handleDeleteIconClick = (prompt) => {
    setPromptToDelete(prompt);
    setDeleteConfirmOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteConfirmOpen(false);
    setPromptToDelete(null);
  };

  const filteredPrompts =
    searchTerm.length > 3
      ? prompts.filter(
          (prompt) =>
            prompt.prompt_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            prompt.prompt_description
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      : prompts;

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
    if (!open) {
      setNewPrompt({ tagName: "", prompt_description: "" });
      setErrors({ tagName: "", prompt_description: "" });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPrompt({ ...newPrompt, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    try {
      setLoading(true);
      // Your API call to submit the new prompt goes here
      const response = await axios.post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/addprompt",
        {
          prompt_name: newPrompt.tagName,
          prompt_description: newPrompt.prompt_description,
        }
      );

      if (response.data.status_code === 200) {
        showSnackbar("Prompt added successfully!");
        setNewPrompt({ tagName: "", prompt_description: "" });
        fetchPrompts(); // Function to refresh the prompts list
        setDrawerOpen(false);
      } else {
        showSnackbar("Failed to add prompt.", "error");
      }
    } catch (error) {
      console.error("Error adding prompt:", error);
      showSnackbar("Error adding prompt. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePrompts = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    const accessToken = storedData.access_token;

    try {
      setLoading(true);
      for (const prompt of prompts) {
        const promptToUpdate = {
          id: prompt.id,
          prompt_name: prompt.prompt_name,
          prompt_description: prompt.prompt_description,
        };

        const response = await axios.put(
          "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/prompts/updateprompt",
          promptToUpdate,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.status_code === 200) {
          console.log(`Prompt ${prompt.id} updated successfully`);
        } else {
          console.error(
            `Failed to update prompt ${prompt.id}:`,
            response.data.message
          );
        }
      }

      showSnackbar("Prompts updated successfully!");
    } catch (error) {
      console.error("Error updating prompts:", error);
      showSnackbar("Error updating prompts. Please try again.", "error"); // Show error Snackbar on exception
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <Container
      className="rfpDtailsContainer"
      fluid
      maxWidth="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "white",
          flexGrow: 1,
          boxShadow: 10,
          mt: 2,
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mb: 2,
            "@media (min-width: 600px)": {
              flexDirection: "row", // Row layout for larger screens
              justifyContent: "space-between",
            },
          }}
        >
          <Typography
            className="promptconfigtext"
            variant="h5"
            sx={{
              fontWeight: 600,
            }}
          >
            Prompt Configuration
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              mt: { xs: 2, md: 0 },
            }}
          >
            <TextField
              className="MuiTextField-searchbar"
              placeholder="Search by RFP Name"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              sx={{
                marginRight: { sm: 2, xs: 0 },
                mb: { xs: 1, sm: 0 },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "9px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ opacity: "40%" }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearSearch}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={toggleDrawer(true)}
              className="Buttons_ESD3 MuiButton-AddNew bg-primary text-capitalize rounded-3"
              sx={{
                bgcolor: "#429bf5",
                mt: { xs: 1, sm: 0 },
              }}
            >
              Add Prompt
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                paddingTop: "14px",
              }}
            >
              {isEditing && (
                <Button
                  variant="contained"
                  className="mb-3 mx-2 bg-primary text-capitalize rounded-3"
                  onClick={handleUpdatePrompts}
                >
                  Update
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: 2, borderRadius: "15px" }}
        >
          <Table>
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                }}
              >
                <CircularProgress className=" text-primary" />
              </Box>
            )}
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>S.No</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Tags</TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  Edit
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Questions</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Edit</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPrompts.map((prompt, index) => (
                <TableRow key={prompt.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell
                    sx={{ fontSize: { xs: "12px", md: "13px", lg: "14px" } }}
                    className="fontstyleet"
                  >
                    {editTagId === prompt.id ? (
                      <TextField
                        value={editTagValue}
                        onChange={(e) => setEditTagValue(e.target.value)}
                        onBlur={() => handleSaveTagClick(prompt.id)}
                        autoFocus
                      />
                    ) : (
                      prompt.prompt_name
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => handleEditTagClick(prompt)}
                    >
                      {editTagId === prompt.id ? (
                        <Save
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      ) : (
                        <ModeEditOutlinedIcon
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: { xs: "12px", md: "13px", lg: "14px" } }}
                    className="fontstyleet"
                  >
                    {editQuestionId === prompt.id ? (
                      <TextField
                        value={editQuestionValue}
                        onChange={(e) => setEditQuestionValue(e.target.value)}
                        onBlur={() => handleSaveQuestionClick(prompt.id)}
                        autoFocus
                        fullWidth
                      />
                    ) : (
                      prompt.prompt_description
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditQuestionClick(prompt)}
                    >
                      {editQuestionId === prompt.id ? (
                        <Save
                          onClick={handleUpdatePrompts}
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      ) : (
                        <ModeEditOutlinedIcon
                          sx={{
                            backgroundColor: "#E5F1FF",
                            color: "#0075FF",
                            padding: "3px",
                            borderRadius: "13px",
                          }}
                        />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteIconClick(prompt)}
                    >
                      <Delete
                        sx={{
                          backgroundColor: "#FFEAEA",
                          color: "#F44336",
                          padding: "3px",
                          borderRadius: "13px",
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => toggleDrawer(false)}
        >
          <Box sx={{ backgroundColor: "#fff", width: 500 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" className="fontstylee mx-3 my-3">
                Add New Prompt
              </Typography>
              <IconButton
                sx={{ marginRight: "7px" }}
                onClick={toggleDrawer(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider className="mx-3" style={{ borderColor: "black" }} />
            <Box p={2} className="drawer-content">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography gutterBottom className="fontstyleeb">
                  Add Prompt Details
                </Typography>
              </Box>
              <form
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Container className="form-container" style={{ flexGrow: 1 }}>
                  <Row>
                    <Col md={12}>
                      <FormControl fullWidth margin="normal">
                        <Typography className="fontstylee pb-2">
                          Tag Name
                        </Typography>
                        <TextField
                          placeholder="Tag Name"
                          name="tagName"
                          value={newPrompt.tagName}
                          onChange={handleInputChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "16px",
                            },
                          }}
                          error={Boolean(errors.tagName)}
                          helperText={errors.tagName}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormControl fullWidth margin="normal">
                        <Typography className="fontstylee pb-2">
                          Question
                        </Typography>
                        <TextField
                          placeholder="Question"
                          name="prompt_description"
                          value={newPrompt.prompt_description}
                          onChange={handleInputChange}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "16px",
                            },
                          }}
                          multiline
                          rows={4}
                          error={Boolean(errors.prompt_description)}
                          helperText={errors.prompt_description}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Container>
                <Container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "46vh",
                    padding: "16px",
                  }}
                >
                  <div style={{ flex: 1 }}></div>
                  <Divider style={{ borderColor: "black" }} />
                  <Row style={{ marginTop: "17px" }}>
                    <Col
                      lg={{ span: 3, offset: 5 }}
                      xs={6}
                      sm={4}
                      md={{ span: 3, offset: 5 }}
                      className="mb-2"
                    >
                      <Button
                        variant="outlined"
                        onClick={toggleDrawer(false)}
                        className="border-primary text-primary text-capitalize rounded-3 cancel-button"
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col lg={4} xs={6} sm={4} md={3} className="ps-4">
                      <Button
                        variant="contained"
                        className="bg-primary text-capitalize rounded-3 add-button"
                        fullWidth
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </form>
            </Box>
          </Box>
        </Drawer>
        <Modal
          open={deleteConfirmOpen}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: "500px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <CloseIcon
                onClick={handleCloseDeleteDialog}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ borderColor: "#adadad" }} />
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete?
            </Typography>
            <Box mt={4} display="flex" justifyContent="end">
              <Button
                variant="outlined"
                className="border-primary text-primary rounded-3"
                onClick={handleCloseDeleteDialog}
              >
                No
              </Button>
              <Button
                variant="contained"
                className="bg-primary mx-2 rounded-3"
                onClick={() => handleDeletePrompt(promptToDelete.id)}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
}

export default PromptConfiguration;
