import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Box } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const headers = [
   'RFP Issuer', 'First Review', 'Conference Due', 'Questionnaire Date', 'Submission Date','POC Mail',  'RFP Type'
];

const statusLabels = {
  '1': <Chip label="New" size="small" className=' border border-primary text-primary rounded-1 activelabel w-100 ' />,
  '2': <Chip label="In-progress" size="small" className="inProgress  border  rounded-1 w-100" />,
};

const icons = {
  '1': <AccessTimeFilledIcon className='text-danger' fontSize='1px'></AccessTimeFilledIcon>,
  '2': <AccessTimeFilledIcon className='text-success' fontSize='1px'></AccessTimeFilledIcon>,
  '3': <AccessTimeFilledIcon className='text-warning' fontSize='1px'></AccessTimeFilledIcon>,
  '4': <AccessTimeFilledIcon className='text-success' fontSize='1px' ></AccessTimeFilledIcon>,
  '5': <AccessTimeFilledIcon className='text-danger' fontSize='1px'></AccessTimeFilledIcon>,
  '6': <AccessTimeFilledIcon className='text-warning' fontSize='1px'></AccessTimeFilledIcon>,
  '7': <AccessTimeFilledIcon className='text-success' fontSize='1px'></AccessTimeFilledIcon>
}

function ActiveData({ searchTerm, dateRange, activeDataa, selectedDueDate, loading }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Filter the data based on searchTerm or other conditions
  const filteredData = activeDataa.filter((row) => {
    const matchesSearchTerm = searchTerm.length > 3 ? row.rfp_issuer.toLowerCase().includes(searchTerm.toLowerCase()) : true;
    return matchesSearchTerm;
  });

  // Calculate total pages dynamically based on filtered data
  const totalRows = filteredData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  // Slice the data for pagination
  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleChangePage = (event, value) => {
    if (value <= totalPages) {
      setCurrentPage(value);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handleKeyPressPage = (event) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(event.target.value.replace(/\D/, ''), 10);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      }
    }
  };

  const handleRowClick = (row) => {
    navigate(`/rfp-preview/${row.rfpid}`);
  };

  return (
    <div>
      <Container fluid>
        <TableContainer component={Paper} className='mt-3'
          sx={{
            width: '100%',
            height: '290px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              height: '3px',
              width: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
              cursor: 'pointer',
            },
          }}

        >
          <Table aria-label="simple table" stickyHeader sx={{ width: '100%' }}>
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '60%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1000,
                }}
              >
                <CircularProgress className=' text-primary' />
              </Box>
            )}
            <TableHead>
              <TableRow sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
                {headers.map(header => (
                  <TableCell key={header} align="left" className="fontstyleeb" sx={{ width: 'auto', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="position-relative">
              {paginatedData.length > 0 ? (
                paginatedData.map((row) => (
                  <TableRow key={row.rfpid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left" className='fontstyleeb text-primary cursor' sx={{ width: 'auto', whiteSpace: 'nowrap' }} onClick={() => handleRowClick(row)}>{row.rfp_issuer} </TableCell>
                    <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.first_review} </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}> {row.conference_date} {icons[row.alerts]} </TableCell>
                    <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.questionnaire_date} {icons[row.alerts]} </TableCell>
                    <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.submission_date} {icons[row.alerts]} </TableCell>
                    <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>{row.contact_email}</TableCell>
                    <TableCell align="left" sx={{ width: 'auto', whiteSpace: 'nowrap' }}> {row.rfp_type} </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                ))
              ) : (
                <Box
                sx={{
                  position: 'absolute', // Absolute position ensures it covers the table
                  top: 100,
                  left: 450,
                }}
              >
                <Typography variant="h6">No Records Found</Typography>
              </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {totalRows > 10 && paginatedData.length > 0 && (
          
          <Box 
          display="flex" 
          flexDirection={{ xs: 'column', sm: 'row' }} // Stack vertically on small screens
          justifyContent="space-between" 
          alignItems="center" 
          mt={1}
          flexWrap="wrap"
          gap={1}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body2" className="mx-1 text-secondary">
              Rows per page:
            </Typography>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              size="small"
              sx={{ minWidth: '20px' }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </Box>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChangePage}
            sx={{
              margin: '0 auto',
              '& .Mui-selected': {
                color: '#1976d2',
              },
            }}
          />
          <Box display="flex" alignItems="center">
            <Typography variant="body2" className="mx-1 text-secondary">
              Go to page:
            </Typography>
            <TextField
              type="number"
              value={currentPage}
              onChange={(event) => setCurrentPage(parseInt(event.target.value, 10))}
              onKeyPress={handleKeyPressPage}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: {
                  width: '60px',
                  textAlign: 'center',
                },
              }}
              size="small"
            />
          </Box>
        </Box>
        )}
      </Container>
    </div>
  );
}

export default ActiveData;
