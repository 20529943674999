import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Button, Box, TextField, Snackbar, Alert } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import './ContentLibrary.css';

const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image']
  ],
};

const formats = [
  'font',
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'link', 'image'
];

const EditSectionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [sectionName, setSectionName] = useState('');
  const [description, setDescription] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const { sectionName, description } = location.state || {};
    setSectionName(sectionName || '');
    setDescription(description || '');
  }, [location.state]);

  const handleUpdate = async () => {
    try {
      const storedData = JSON.parse(localStorage.getItem('userDetails'));
      const accessToken = storedData.access_token;

      await axios.put(`https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/contentlibrary/updateclsection`, {
        id: id,
        content_name: sectionName,
        content_description: description,
        content_file: ''
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setOpenSnackbar(true);

      setTimeout(() => {
        navigate(`/content-library`, {
          state: { row: { content_name: sectionName, content_description: description } }
        });
      }, 1500);

    } catch (error) {
      console.error('Error updating section:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container className='rfpDtailsContainer' maxWidth="100%"  sx={{
      padding: { xs: 1, sm: 2, md: 3 },
    }}>
      <Box mt={2}>
        <Paper elevation={3}  sx={{
          padding: { xs: 2, sm: 3, md: 5 },
          borderRadius: '15px',
        }}>
          <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
            <Typography variant="h5" gutterBottom className='fontstyleeb'>
              Edit Section Details
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} className='buttonbackss Buttons_ESD1 border-primary text-primary text-capitalize rounded-3' sx={{
              width: { xs: '100%', sm: 'auto' },
              marginBottom: { xs: 2, sm: 0 },
            }}>
              Back
            </Button>
          </Box>

          <Box mb={2} className='formesd'>
            <Typography variant="h6" mt={2} mb={1} color="GrayText" className='fontstylee'>
              Section Name
            </Typography>
            <TextField
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
              variant='outlined'
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
                marginBottom: 3,
                width: { xs: '100%', sm: '75%', md: '50%' },
              }}
            />
          </Box>

          <Box mb={2}>
            <Typography variant="h6" mb={1} color="GrayText" className='fontstylee'>
              Description
            </Typography>
            <ReactQuill
              modules={modules}
              formats={formats}
              value={description}
              onChange={setDescription}
              style={{ height: '200px', marginBottom: '20px' }}
            />
          </Box>

          <Box className='buttonsrfp' display="flex" justifyContent="flex-end" flexDirection={{ xs: 'column', sm: 'row' }} mt={8}>
            <Button variant="outlined" onClick={() => navigate(-1)} className='border-primary text-primary text-capitalize rounded-3' sx={{
              width: { xs: '100%', sm: 'auto' },
              marginBottom: { xs: 2, sm: 0 },
              mx: { sm: 2 }
            }}>
              Cancel
            </Button>
            <Button variant="contained" className='bg-primary text-capitalize rounded-3' onClick={handleUpdate} sx={{
              width: { xs: '100%', sm: 'auto' }
            }}>
              Update
            </Button>
          </Box>
        </Paper>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Section updated successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditSectionDetails;
