import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import documentSigned from '../../assets/Images/document-signed.png'
import SubmittedMemoCircleCheckImage from '../../assets/Images/memo-circle-check.png'
import clockThreeImage from '../../assets/Images/clock-three.png'
import InprogresshourglassStart from '../../assets/Images/hourglass-start.png'
import FormControl from '@mui/material/FormControl';
import { TextField, MenuItem, Select, InputLabel, RadioGroup, CardActions } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Radio from '@mui/material/Radio';
import Modal from '@mui/material/Modal';
import { Close as CloseIcon } from '@mui/icons-material';
import ActiveData from "./Tables/ActiveData"
import SubmittedData from "./Tables/SubmittedData"
import InProgresData from "./Tables/InProgresData"
import OnHoldData from "./Tables/OnHoldedData"
import AlertError from '../../assets/Images/AlertError.svg';
import FileUpload from './fileUpload'
import axios from 'axios';
import './Dashboard.css'



function Dashboard({hideFunctions}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDueDate, setSelectedDueDate] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const [activeTable, setActiveTable] = useState("ActiveCard");
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [data, setData] = useState({
    active: 0,
    in_progress: 0,
    on_hold: 0,
    submitted: 0,
    total: 0,

  });
  const [activeDataa, setActiveData] = useState([]);
  const [rfpType, setRfptype] = useState('')
  const [dateRange, setDateRange] = useState([])
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [popupDetails, setPopupDetails] = useState("");


  const refreshtoken = async () => {
    const storedData = JSON.parse(localStorage.getItem("userDetails"));
    if (!storedData) return;

    const userId = storedData.userDetails.id;
    try {
      const data = { userid: userId };
      const response = await axios.post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/refreshtoken",
        JSON.stringify(data),
        { headers: { "Content-Type": "application/json" } }
      );

      if (response?.data?.status_code === 200) {
        const { userDetails, access_token } = response.data;
        const userData = { userDetails, access_token };
        localStorage.setItem("userDetails", JSON.stringify(userData));
        window?.location.reload();
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };


  function checkReminder(targetDateTime) {
    const targetDate = new Date(targetDateTime?.date);
      const currentDate = new Date();
      const reminderTime = new Date(targetDate);
    reminderTime.setMinutes(reminderTime.getMinutes() - 30);
  
    if (currentDate >= reminderTime && currentDate < targetDate) {
      setPopupDetails(targetDateTime);
      setAlertMessage(targetDateTime?.message);
      setAlertModalShow(true);  
    } else if (currentDate >= targetDate) {
      console.log("The target time has passed.");
    } else {
      console.log("No reminder needed yet.");
    }
  }

  const fetchAlertData = async () => {
    try {
      const storedData = JSON.parse(localStorage.getItem("userDetails"));
      const token = storedData?.access_token;
      const userId = storedData.userDetails.id;

      if (!token) throw new Error("No token available");

      const response = await axios.get(
        `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/reminderalerts?userid=${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.details && response.data.details.length > 0) {
        const data = response.data;
        if(data?.details?.length > 0){
          data && data?.details.map((e)=>{
            checkReminder(e)
          })
        }
      } else {
        setAlertModalShow(false); 
      }
    } catch (error) {
      console.error("Error fetching alert data:", error);
      setAlertMessage(
        error.response?.status === 401
          ? "Session expired. Please log in again."
          : "Error fetching alert data."
      );
      if (error.response?.status === 401) {
        refreshtoken();
        localStorage.removeItem("userDetails");
      }
      setAlertModalShow(false);
    }
  };



  


  useEffect(()=> { fetchAlertData()},[])
  useEffect(() => {
    // Call refreshtoken every 5 minutes
    const refreshInterval = setInterval(fetchAlertData, 5 * 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(refreshInterval);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const storedData = JSON.parse(localStorage.getItem('userDetails'));
        const accessToken = storedData.access_token;
        const getStatus = (activeTable) => {
          switch (activeTable) {
            case 'ActiveCard': return 1;
            case 'InProgressData': return 2;
            case 'OnHoldCard': return 3;
            case 'SubmitCard': return 4;
            default: return 0;
          }
        };

        const status = getStatus(activeTable);
        const params = new URLSearchParams({
          search: searchTerm || '',
          fromDate: dateRange[0] || '',
          toDate: dateRange[1] || '',
          rfpType: rfpType || '',
          dueDate: selectedDueDate || '',
          page: 0,
          pageSize: 20,
          status: status || ''
        });
        const url = `https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/activerfp?${params.toString()}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setActiveData(response.data.results);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.status === 401) {
          refreshtoken();
        }
      }
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm, rfpType, dateRange, activeTable, selectedDueDate,]);



  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userDetails'));


    if (storedData && storedData.userDetails && storedData.userDetails.id) {
      const userId = storedData.userDetails.id;
      if (userId) {
        axios.post(
          'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/activitiesoverview',
          { userid: userId }
        )
          .then(response => setData(response.data))
          .catch(error => console.error('Error fetching data:', error));
      } else {
        console.error('User ID not found in userDetails');
      }
    } else {
      console.error('No userDetails found in localStorage or id is missing');
    }
  }, []);

  const handlemodalClose = () => setAlertModalShow(false);

  const handlePopupResponse = (id) => {
    localStorage.setItem('AlertPopupResponse', id);
    setAlertModalShow(false);
    const storedData = JSON.parse(localStorage.getItem('userDetails'));
    const userId = storedData.userDetails.id;

    const commentData = {
      userid: userId,
      comments: id,
      rfpid: popupDetails?.rfp_id,
      status: true
    }

    console.log(commentData);

    axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/addrfpcomment', commentData)
      .then(response => {
        console.log('Comment saved:', response.data);
        // setAlertData('');
      })
      .catch(error => {
        console.error('Error saving comment:', error.response?.data || error.message);
      });
  };


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // cleanUp();
    setOpen(false);
  };

  const handleApplyRfptype = (event) => {
    setRfptype(event.target.value);
  };
  const handleClearRfpType = () => {
    setRfptype('');
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterModalOpenChange = () => {
    setFilterModalOpen(true)
  }

  const handleFilterModalCloseChange = () => {
    setFilterModalOpen(false)
  }

  const handleDueDateChange = (event) => {
    setSelectedDueDate(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };



  // Function to clear the due date selection
  const handleClearDueDate = () => {
    setSelectedDueDate('');
  };

  const handleClearDateRange = () => {
    setDateRange([]);
  };
  


  return (

    <Container fluid className='maincontainer'>
      <Row className='rows'>
      {!hideFunctions && 
        <Paper className='mt-4 pb-4 rounded-4 paper' style={{ width: '100%' }}>
          <div>
            <Typography className='fontstyleeb fs-6 px-2 mt-2'>Overview</Typography>
          </div>
          <Container fluid>
            <Row className="g-3 pt-2 ">
              <Col xs={12} lg={3} md={6} sm={12} className='TotalRpf'>
                <Card sx={{ display: 'flex', p: { xs: 0 }, border: '1px solid rgba(215, 233, 245)', borderRadius: 3, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }} className='cardContent'>
                      <div className="pl-3 d-flex justify-content-center align-items-center imagebackgrounds" style={{ backgroundColor: 'rgba(215, 233, 245)', borderRadius: '10px' }}>
                        {/* Render your icon */}
                        <img src={documentSigned} alt='documentSigned' width={'32px'} className='cardImages' />
                      </div>
                    </CardContent>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className='fontstylee cardText'>New RFP<br /> <span className='text-primary fw-bold fs-4 cardTextCount'>{data.active}/{data.total}</span></Typography>
                  </Box>
                </Card>
              </Col>

              <Col xs={12} lg={3} md={6} sm={12} className='Submitted'>
                <Card sx={{ display: 'flex', p: { xs: 0, }, border: '1px solid rgba(215, 233, 245)', borderRadius: 3, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}  >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }} className='cardContent'>
                      <div className=" pl-3 d-flex justify-content-center align-items-center imagebackgrounds" style={{ backgroundColor: 'rgba(215, 233, 245)', borderRadius: '10px' }}>
                        {/* Render your icon */}
                        <img src={SubmittedMemoCircleCheckImage} alt='memoCircleCheck' width={'32px'} className='cardImages' />
                      </div>
                    </CardContent>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className='fontstylee cardText'>Submitted<br /><span className='text-primary fw-bold fs-4 cardTextCount '>{data.submitted}/{data.total}</span></Typography>
                  </Box>
                </Card>
              </Col>

              <Col xs={12} lg={3} md={6} sm={12} className='onHold'>
                <Card sx={{ display: 'flex', p: { xs: 0, }, border: '1px solid rgba(215, 233, 245)', borderRadius: 3, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }} >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }} className='cardContent'>
                      <div className=" pl-3 d-flex justify-content-center align-items-center imagebackgrounds" style={{ backgroundColor: 'rgba(215, 233, 245)', borderRadius: '10px' }}>
                        {/* Render your icon */}
                        <img src={clockThreeImage} alt='clockImage' width={'32px'} className='cardImages' />
                      </div>
                    </CardContent>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography className='fontstylee cardText'>On Hold <br /><span className='fw-bold fs-4 text-primary cardTextCount'>{data.on_hold}/{data.total}</span></Typography>
                  </Box>
                </Card>
              </Col>

              <Col xs={12} lg={3} md={6} sm={12}>
                <Card sx={{ display: 'flex', p: { xs: 0, }, border: '1px solid rgba(215, 233, 245)', borderRadius: 3, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }} className='cardContent'>
                      <div className=" pl-3 d-flex justify-content-center align-items-center imagebackgrounds" style={{ backgroundColor: 'rgba(215, 233, 245)', borderRadius: '10px' }}>
                        <img src={InprogresshourglassStart} alt='hourglassStart' width={'32px'} className='cardImages' />
                      </div>
                    </CardContent>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className='fontstylee cardText'>In Progress<br /><span className='text-primary fw-bold fs-4 cardTextCount'>{data.in_progress}/{data.total}</span></Typography>
                  </Box>
                </Card>
              </Col>
            </Row>

          </Container>
        </Paper>}

        <Paper className='rounded-4 mt-3 tabscard pb-3' >
          <div>
            <Container fluid>
              <Row className='pt-4'>
                <Col xs={12} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === 'ActiveCard' ? 'text-primary border-bottom border-3 border-primary pb-2 borderWidth' : 'text-secondary'}`}
                    onClick={() => setActiveTable("ActiveCard")}
                  >
                    New RFP
                  </Typography>
                </Col>
                <Col xs={12} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === 'SubmitCard' ? 'text-primary border-bottom border-3 border-primary pb-2 borderWidth' : 'text-secondary'}`}
                    onClick={() => setActiveTable("SubmitCard")}
                  >
                    Submitted
                  </Typography>
                </Col>
                <Col xs={12} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === 'OnHoldCard' ? 'text-primary border-bottom border-3 border-primary pb-2 borderWidth' : 'text-secondary'}`}
                    onClick={() => setActiveTable("OnHoldCard")}
                  >
                    On Hold
                  </Typography>
                </Col>
                <Col xs={12} lg={3} sm={3} md={3}>
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    className={`fontstyleeb d-flex justify-content-center ${activeTable === 'InProgressData' ? 'text-primary border-bottom border-3 border-primary pb-2 borderWidth' : 'text-secondary'}`}
                    onClick={() => setActiveTable("InProgressData")}
                  >
                    In-Progress
                  </Typography>
                </Col>
              </Row>

              <Divider className='mb-4 border-secondary' />

              <Row className='selects g-3' style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Col xs={12} sm={6} md={4} xl={2} lg={2}>
                  <Stack direction="column" alignItems="flex-start">
                    <DateRangePicker
                      showHeader={false}
                      caretAs={KeyboardArrowDownIcon}
                      ranges={predefinedRanges}
                      placeholder="Select date"
                      className="fontstyleet datepicker"
                      size="md"
                    
                      style={{ width: '100%' }}
                      cleanable
                      onClean={handleClearDateRange}
                      onChange={(value) => setDateRange(value)}
                      onShortcutClick={(shortcut, event) => {
                        console.log(shortcut);
                      }}
                    />
                  </Stack>
                </Col> */}

                <Col xs={12} sm={6} md={4} xl={2} lg={2} className='inputfieldsCol due-date-col '>
                  <FormControl fullWidth size="small" className='inputfields'>
                    <InputLabel sx={{ fontSize: '12px' }} id="select-label">Due Date</InputLabel>
                    <Select
                      labelId="select-label"
                      className='inputfields rounded-3 '
                      value={selectedDueDate !== undefined ? selectedDueDate : ''}
                      onChange={handleDueDateChange}
                      label="Select Date"
                      sx={{
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '12px', // Decrease the placeholder font size
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '16px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: '6px',
                            borderRadius: '10px',
                          },
                        },
                      }}
                      IconComponent={selectedDueDate ? () => null : undefined}
                      endAdornment={selectedDueDate && (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={handleClearDueDate}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                    >
                      <MenuItem value='Within a Week'>Within a Week</MenuItem>
                      <MenuItem value='Within 10 Days'>Within 10 Days</MenuItem>
                      <MenuItem value='Within 2 Weeks'>Within 2 Weeks</MenuItem>
                      <MenuItem value='Within a Month'>Within a Month</MenuItem>
                      <MenuItem value='Within 2 Months'>Within 2 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} sm={6} md={4} xl={3} lg={3} className='inputfieldsCol'>
                  <TextField
                    fullWidth
                    placeholder="Search by RFP Name/Type"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    className='fontstyleet  searchbar'
                    sx={{
                      width: '100%',
                      '@media (min-width: 769px) and (max-width: 1440px)': {
                        minWidth: '160px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '12px', // Decrease the placeholder font size
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '9px',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ opacity: '40%' }} />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={handleClearSearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} sm={6} md={4} xl={2} lg={2} className='inputfieldsCol'>
                  <FormControl fullWidth size="small" className='inputfields'>
                    <InputLabel sx={{ fontSize: '12px' }} className='fontstyleet' id="select-label">RFP Type</InputLabel>
                    <Select
                      labelId="select-label"
                      className='inputfields rounded-3'
                      value={rfpType !== undefined ? rfpType : ''}
                      onChange={handleApplyRfptype}
                      label="Select RFP Type"

                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: '6px',
                            borderRadius: '10px',
                          },
                        },
                      }}
                      IconComponent={rfpType ? () => null : undefined}
                      endAdornment={rfpType && (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={handleClearRfpType}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )}

                    >

                      <MenuItem value='solutioning'>Solutioning</MenuItem>
                      <MenuItem value='staffing'>Staffing</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={2}>
                </Col>

                <Col xs={3} sm={2} md={1} xl={1} lg={1} className='inputfieldsCol filterButton '>
                  <Button variant="outlined" sx={{ width: '100%', maxWidth: '200px' }} className='filterIcon border border-primary rounded-2'>
                    <FilterAltOutlinedIcon onClick={handleFilterModalOpenChange} className='text-primary' />
                  </Button>
                </Col>

                <Col xs={7} sm={3} md={3} xl={2} lg={2} className='addButton ps-3'>
                  <Button variant="contained" onClick={handleOpen} sx={{ width: '100%', maxWidth: '200px' }} className='bg-primary text-capitalize rounded-2'>
                    Add RFP
                  </Button>
                </Col>
              </Row>
            </Container>

            {/* Pass the state and handlers as props to the table component */}
            <Container fluid className='mt-2 mb-1' style={{ padding: 0, width: '100%' }}>
              {activeTable === "ActiveCard" && <ActiveData
                activeDataa={activeDataa}
                searchTerm={searchTerm}
                dateRange={dateRange}
                rfpType={rfpType}
                selctedDueDate={selectedDueDate}
                loading={loading}
              />}
              {activeTable === "SubmitCard" && <SubmittedData
                activeDataa={activeDataa}
                searchTerm={searchTerm}
                rfpType={rfpType}
                selctedDueDate={selectedDueDate}
              />}
              {activeTable === "OnHoldCard" && <OnHoldData
                activeDataa={activeDataa}
                searchTerm={searchTerm}
                rfpType={rfpType}
                selctedDueDate={selectedDueDate}
              />}
              {activeTable === "InProgressData" && <InProgresData
                activeDataa={activeDataa}
                searchTerm={searchTerm}
                rfpType={rfpType}
                selctedDueDate={selectedDueDate}
              />}
            </Container>


            <FileUpload open={open} onClose={handleClose} />

            <Container fluid>
              <Modal
                open={filterModalOpen}
                onClose={handleFilterModalCloseChange}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='filterModal'
              >
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: { xs: '90%', sm: '80%', md: '70%', lg: '60%', xl: '50%' },
                  maxWidth: '800px', // Max width for larger screens
                  maxHeight: { xs: '90%', sm: '80%' },
                  overflowY: 'auto',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 3,
                  borderRadius: 2
                }}

                >
                  <div className='d-flex justify-content-between'>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ fontWeight: '500' }} className='filterModalHeading'>
                      Filters
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleFilterModalCloseChange}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Divider className='my-1' sx={{ opacity: '0.6' }} />
                  <Typography id="modal-modal-description" className='mb-2 filterModalHeading' sx={{ fontWeight: '500' }} variant='h5'>
                    Select columns to filter the data
                  </Typography>
                  <Row>
                    <Col lg={3} sm={6} xs={6} md={3} ><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>First Review </span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>RFP Issuer</span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>RFP Type </span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Conference Date</span>} /></Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Conference details</span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Submission Mode </span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Location </span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>POC Name </span>} /></Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>POC Email </span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Questionnaire Date</span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Budget Range</span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Expected Timeline </span>} /></Col>
                  </Row>
                  <Row>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Proposal Due </span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Status</span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Remarks</span>} /></Col>
                    <Col lg={3} sm={6} xs={6} md={3}><FormControlLabel control={<Checkbox className='text-primary ' />} label={<span className='selectFilters'>Website</span>} /></Col>
                  </Row>
                  <Typography id="modal-modal-description" sx={{ fontWeight: '500' }} className='mt-3 filterModalHeading' variant="h5">
                    Sort Data by
                  </Typography>
                  <RadioGroup>
                    <Row>
                      <Col lg={3} xs={6} md={3}><FormControlLabel value="Sort by A-Z" control={<Radio className='text-primary' />} label={<span className='selectFilters'>Sort By A-Z </span>} /></Col>
                      <Col lg={3} xs={6} md={3}><FormControlLabel value="Sort by Z-A" control={<Radio className='text-primary' />} label={<span className='selectFilters'>Sort By Z-A</span>} /></Col>
                      <Col lg={3} xs={6} md={3}><FormControlLabel value="Newest First" control={<Radio className='text-primary' />} label={<span className='selectFilters'>Newest First</span>} /></Col>
                      <Col lg={3} xs={6} md={3}><FormControlLabel value="Oldest First" control={<Radio className='text-primary' />} label={<span className='selectFilters'>Oldest First</span>} /></Col>
                    </Row>
                  </RadioGroup>
                  <Divider sx={{ my: 1, opacity: '0.6' }} />
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="outlined" sx={{ marginRight: 2 }} size="small" onClick={handleFilterModalCloseChange} className='border-primary text-primary rounded-2'>
                      Cancel
                    </Button>
                    <Button variant="contained" size="small" className='bg-primary rounded-2'>
                      Apply Filters
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Container>
          </div>

          <Modal
  open={AlertModalShow}
  onClose={handlemodalClose}
  aria-labelledby="alert-card-title"
  aria-describedby="alert-card-description"
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <Card sx={{ maxWidth: 550, position: 'relative', padding: '16px', borderRadius: '12px' }} className='meetingNotification'>
      <Typography variant="h4" className='fontstyleeb ps-2 alertText1' component="div">
        Alert
      </Typography>
      <Divider style={{ margin: '10px 0', borderColor: '#adadad' }} />
      <IconButton
        onClick={handlemodalClose}
        style={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent style={{ textAlign: 'center', padding: 5 }}>
        <img src={AlertError} alt='ALERTS' className='meetingNotification_image' />
        <Typography variant="h4" className='mt-3 fontstyleeb alertText'>
          {alertMessage || 'Loading alert...'}
        </Typography>
      </CardContent>
      <Divider style={{ margin: '10px 0', borderColor: '#adadad' }} />
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" className='border border-primary text-primary rounded-4 p-2 meetingButtons text-capitalize' onClick={() => handlePopupResponse("No, am not attending")}>
          No, am not attending
        </Button>
        <Button variant="contained" className='mx-2 bg-primary text-white rounded-4 p-2 meetingButtons text-capitalize' onClick={() => handlePopupResponse("Yes, am attending")}>
          Yes, am attending
        </Button>
      </CardActions>
    </Card>
  </Box>
</Modal>
        </Paper>
      </Row>
    </Container >

  );
}

export default Dashboard;