import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useSnackbar } from '../SnackbarContext'; 
import './ContentLibrary.css';

const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image'],
  ],
};

const formats = [
  'font',
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'align',
  'link', 'image',
];

const AddNewSection = () => {
  const navigate = useNavigate();
  const [sectionName, setSectionName] = useState('');
  const [description, setDescription] = useState('');
  const { showSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({ sectionName: '', description: '' });

  const validate = () => {
    let isValid = true;
    const newErrors = { sectionName: '', description: '' };

    if (!sectionName.trim()) {
      newErrors.sectionName = 'Section Name is required';
      isValid = false;
    } else if (sectionName.length < 3) {
      newErrors.sectionName = 'Section Name must be at least 3 characters long';
      isValid = false;
    }

    if (!description.trim()) {
      newErrors.description = 'Description is required';
      isValid = false;
    } else if (description.length < 10) {
      newErrors.description = 'Description must be at least 10 characters long';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (!validate()) return;

    console.log('Saving section...');
    try {
      const requestData = {
        content_name: sectionName,
        content_description: description,
        content_file: '', // If you have a file input, handle it here
      };

      const response = await axios.post(
        'https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/contentlibrary/addclsection',
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('API response:', response);

      if (response.status === 200) {
        showSnackbar('Section added successfully!');
        // Reset fields and errors
        setSectionName('');
        setDescription('');
        setErrors({ sectionName: '', description: '' }); // Reset errors here
        navigate('/content-library'); // Navigate to the content library page after success
      }
    } catch (error) {
      console.error('Error adding section:', error);
      showSnackbar('Error adding section. Please try again.', 'error');
    }
  };

  // Handle input changes and clear errors
  const handleSectionNameChange = (e) => {
    setSectionName(e.target.value);
    if (errors.sectionName) {
      setErrors((prevErrors) => ({ ...prevErrors, sectionName: '' })); 
    }
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    if (errors.description) {
      setErrors((prevErrors) => ({ ...prevErrors, description: '' })); 
    }
  };

  return (
    <Container fluid className='rfpDtailsContainer' style={{ maxWidth: '100%' }}>
      <Box mt={2}>
        <Paper className='paperss' elevation={3} style={{ padding: 20, borderRadius: 15, width: '100%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className='addnewsection' variant="h4">
              <strong>Add New Section</strong>
            </Typography>
            <Button variant="outlined" className='back-button border-primary text-primary text-capitalize rounded-3' onClick={() => navigate(-1)}>
              Back
            </Button>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">
              <strong>Section Name</strong>
            </Typography>
            <TextField
              value={sectionName}
              placeholder='Section Name'
              onChange={handleSectionNameChange} // Use the new change handler
              variant='outlined'
              fullWidth
              className="text-field"
              error={!!errors.sectionName}
              helperText={errors.sectionName}
              sx={{ marginTop: 1, '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
            />
          </Box>
          <Box mt={3}>
            <Typography variant="h6">
              <strong>Description</strong>
            </Typography>
          </Box>
          <Box>
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange} // Use the new change handler
              className='quill-editor'
              modules={modules}
              formats={formats}
              placeholder='Section Description'
              style={{ height: '150px', marginTop: '10px' }}
            />
            {errors.description && (
              <Typography color="error" variant="body2">
                {errors.description}
              </Typography>
            )}
          </Box>
          <Box className='Buttons1' mt={3} display="flex" justifyContent="flex-end" marginTop={8}>
            <Button variant="contained" className='save-button Buttons_ESD4 bg-primary text-capitalize rounded-3' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default AddNewSection;
