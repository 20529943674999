import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Paper,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import "./Microsoftrfp.css";
import ChatBot from "./Chat";
import PDF from "../../../assets/Images/PDF.svg";
import ClipLoader from "react-spinners/ClipLoader";

const MicrosoftRFP = ({ onBackButtonClick, documentResponse, document }) => {
  const [value, setValue] = useState("1");
  const [selectedContent, setSelectedContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  const handleData = (key) => {
    setData(documentResponse[key]);
    setSelectedContent(key);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      setSelectedContent(<ChatBot id={document} />);
    } else {
      setSelectedContent(null);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader color="rgb(12,140,233)" loading={loading} size={50} />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "20px" }} className="chatbotpage">
      <div className="container">
        <div className="row">
          <Box
            className="col-12 d-flex justify-content-between align-items-center"
            sx={{
              padding: { xs: "8px", sm: "10px" },
              borderRadius: "8px",
              marginBottom: "20px",
              flexDirection: { xs: "column", sm: "row" }, // Stack on mobile
            }}
          >
            <div className="d-flex align-items-center mb-2 mb-sm-0">
              <IconButton>
                <img src={PDF} alt="pdf" width="25px" height="25px" />
              </IconButton>
              <Typography
                variant="h6"
                className="fontstylee docname"
                sx={{ fontSize: { xs: "10px", sm: "inherit" } }}
              >
                {document?.doc_name || "Projectscope.pdf"}
              </Typography>
            </div>
            <Button
              onClick={onBackButtonClick}
              className="mt-2 mt-sm-0" // Add margin on top in mobile view
              sx={{
                color: "rgb(1,117,255)",
                borderColor: "rgb(1,117,255)",
                "&:hover": { borderColor: "rgb(1,117,255)" },
                fontSize: { xs: "8px", sm: "inherit" }, // Smaller font on mobile
              }}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </div>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            width: "100%",
            typography: "body1",
            padding: { xs: "8px", sm: "12px", md: "16px" }, // Responsive padding
          }}
        >
          <TabContext value={value}>
            <Grid
              className="tabsrfp"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack tabs on mobile
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: { backgroundColor: "rgb(1,117,255)" },
                }}
                sx={{
                  minWidth: { xs: "auto" }, // Compress width on mobile
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Tab
                  label="Default Extracted Answers"
                  className="fontstyleeb text-capitalize"
                  value="1"
                  sx={{
                    fontSize: { xs: "8px", sm: "14px" }, // Decreased font size for mobile
                    padding: { xs: "2px 4px", sm: "6px 12px" },
                    minWidth: "auto",
                    maxWidth: { xs: "120px", sm: "none" },
                    "&.Mui-selected": {
                      color: "rgb(1,117,255)",
                    },
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                />
                <Tab
                  label="Chat with PDF"
                  className="fontstyleeb text-capitalize"
                  value="2"
                  sx={{
                    fontSize: { xs: "8px", sm: "14px" }, // Decreased font size for mobile
                    padding: { xs: "2px 4px", sm: "6px 12px" },
                    minWidth: "auto",
                    maxWidth: { xs: "120px", sm: "none" },
                    "&.Mui-selected": {
                      color: "rgb(1,117,255)",
                    },
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                />
              </TabList>
            </Grid>
          </TabContext>
        </Grid>

        {value === "1" && (
          <Box sx={{ mt: 2, width: "100%" }}>
            <Grid container spacing={2}>
              {Object.keys(documentResponse).map((key, index) => (
                <Grid item xs={6} sm={4} md={3}>
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "rgb(12,140,233,0.1)",
                      boxShadow: "0",
                      "&:hover": {
                        backgroundColor: "rgb(12,140,233)",
                        color: "white",
                      },
                    }}
                    variant="contained"
                    fullWidth
                    key={index}
                    onClick={() => handleData(key)}
                  >
                    {key}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        <Box
          sx={{
            mt: 2,
            width: "100%",
            whiteSpace: "pre-line",
            textAlign: "justify",
          }}
        >
          {value === "2" ? (
            <>
            {selectedContent}
            </>
          ):(
            <div>
              <Typography
                className="fontstyleeb"
                variant="h6"
                sx={{
                  color: "rgb(12,140,233)",
                  borderBottom: "1px solid",
                  paddingBottom: "5px",
                }}
              >
                {selectedContent && selectedContent + ":"}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: "10px" }}>
                {(data && data) || "No data available"}
              </Typography>
            </div>
          )}
        </Box>
      </Box>

      {/* <Box sx={{ mt: 2 }}>
        <Chat/>
        </Box> */}
    </Box>
  );
};

export default MicrosoftRFP;
