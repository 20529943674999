import React, { useState, useEffect } from "react";
import "./Chat.css";
import SendIcon from "../../../assets/Images/send.svg";
import Microphone from "../../../assets/Images/microphone.svg";
import RobotIcon from "../../../assets/Images/RobotIcon.svg";
import UserIcon from "../../../assets/Images/userIcon.svg";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner loader

function ChatBot({id}) {
console.log("sdvxvx--->", id)
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false); // State for loading spinner

  const sendMessage = () => {
    if (userInput.trim() === "") return; // Prevent sending empty messages
    handlesubmitchat(userInput);
    setUserInput("");
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const chatHistory = () => {
    axios
      .get(`https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/doc_extract/pdfchathistory?doc_id=${id?.doc_id}`)
      .then((response) => {
        const historyMessages = response.data.results.flatMap((item) => [
          { type: "user", text: item.question },
          { type: "bot", text: item.answer },
        ]);
        // Set history messages only once
        setChatMessages(historyMessages);
      })
      .catch((error) => {
        console.error("Error fetching chat history:", error);
      });
  };

  useEffect(() => {
    chatHistory();
  }, []);

  const handlesubmitchat = (userMessage) => {
    const newUserMessage = { type: "user", text: userMessage };
    setChatMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setLoading(true); // Show the spinner when making API request
    axios
      .post(
        "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/doc_extract/pdfchat",
        {
          doc_id: id?.doc_id,
          prompt: userMessage,
        }
      )
      .then((response) => {
        const results = response.data.results;
        const botMessages = results.map((item) => ({
          type: "bot",
          text: item.answer,
        }));
        setChatMessages((prevMessages) => [...prevMessages, ...botMessages]);
      })
      .catch((error) => {
        console.error("Error:", error);
        const errorMessage = {
          type: "bot",
          text: "I'm having trouble connecting. Please try again later.",
        };
        setChatMessages((prevMessages) => [...prevMessages, errorMessage]);
      })
      .finally(() => {
        chatHistory();
        setLoading(false); // Hide the spinner after the API response
      });
  };

  const startVoiceRecognition = () => {
    const recognition =
      new window.webkitSpeechRecognition() || new window.SpeechRecognition();
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setUserInput(transcript);
      sendMessage();
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
    };

    recognition.start();
  };

  return (
    <div className="chat-container">
      <div id="chat-messages">
        {chatMessages.length === 0 ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", flexDirection: "column" }}>
            <img
              style={{ width: 300, height: 200, marginLeft: 10 }}
              src={
                "https://img.freepik.com/premium-vector/blue-robot-holding-sign-with-space-chatbot-icon-concept-chat-bot-chatterbot-png-image_105700-351.jpg"
              }
              alt="chatImage"
            />
            <p>
              Type your first message or question below.
            </p>
          </div>
        ) : (
          <div>
            {chatMessages.map((message, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    message.type === "user" ? "flex-end" : "flex-start",
                }}
              >
                {message.type !== "user" && (
                  <img
                    src={RobotIcon}
                    alt="Robot"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                    className="mt-1"
                  />
                )}
                <p
                  className={`${message.type}-message`}
                  style={{
                    textAlign: message.type === "user" ? "right" : "left",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {message.type === "user" ? " " : ""}
                  </span>
                  {message.text}
                </p>
                {message.type === "user" && (
                  <img
                    src={UserIcon}
                    alt="User"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginLeft: "10px",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {loading && ( // Conditionally render the spinner while loading
        <div className="loading-spinner">
          <ClipLoader size={20} color={"#123abc"} loading={loading} />
        </div>
      )}

      <div className="input_field">
        <input
          type="text"
          id="user-input"
          class="form-control"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Write your message"
        />
        <div className="buttonslastrfp d-flex align-items-start pt-1">
          <img
            onClick={startVoiceRecognition}
            src={Microphone}
            alt="Voice"
            className="voice-icon"
            width="20px"
          />
          <img
            onClick={sendMessage}
            src={SendIcon}
            alt="Send"
            className="sendImage"
            width="20px"
          />
        </div>
      </div>
    </div>
  );
}

export default ChatBot;
