import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Button, Box, Link, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PDF from '../../assets/Images/PDF.svg';
import axios from 'axios';
import './ContentLibrary.css';

const SectionDetails = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { id } = useParams();
  const [sectionData, setSectionData] = useState(null);
  const [versionHistory, setVersionHistory] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');
  
  // Token management
  const storedData = JSON.parse(localStorage.getItem('userDetails'));
  let token = storedData?.access_token;

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const refreshtoken = async () => {
    const userId = storedData.userDetails.id;
    try {
      const data = {
        userid: userId,
      };
      const response = await axios({
        method: "POST",
        url: "https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/refreshtoken",
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.data?.status_code === 200) {
        const { userDetails, access_token } = response.data;

        const userData = {
          userDetails,
          access_token,
        };
        localStorage.setItem("userDetails", JSON.stringify(userData));
        token = access_token; // Update token in the current context
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSectionDetails = async () => {
    try {
      const response = await axios.get(`https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/contentlibrary/getspecific?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const { data } = response.data;
        setSectionData(data);
        setVersionHistory(data.version_history);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        // Token might be expired; attempt to refresh it
        await refreshtoken();
        fetchSectionDetails(); // Retry fetching data after refreshing token
      } else {
        console.error('Error fetching section details:', error);
      }
    }
  };

  // Fetch section details on mount
  useEffect(() => {
    fetchSectionDetails();
  }, []);

  const Sidebar = ({ onClose }) => (
    <Box p={3} width={isMobile ? '100%' : '400px'} role="presentation">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" className='fontstyleeb' gutterBottom>
          Version History
        </Typography>
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      <TableContainer component={Paper} sx={{ borderColor: 'ActiveBorder', borderRadius: '20px', marginTop: '15px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='fontstyleeb'>S.No</TableCell>
              <TableCell className='fontstyleeb'>Version</TableCell>
              <TableCell className='fontstyleeb'>Updated On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versionHistory.map((version, index) => (
              <TableRow key={version.slno}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{version.version}</TableCell>
                <TableCell>{version.updated_on}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider style={{ marginTop: '34px', borderColor: 'black' }} />
      <Row style={{ marginTop: '15px' }}>
        <Col lg={{ span: 3, offset: 9 }}>
          <Button
            variant="contained"
            className='bg-primary text-capitalize rounded-3'
            fullWidth
            onClick={onClose}
          >
            Close
          </Button>
        </Col>
      </Row>
    </Box>
  );

  if (!sectionData) return <Typography>Loading...</Typography>; // Loading state

  return (
    <Container className='rfpDtailsContainer' maxWidth="100%">
    <Box mt={2}>
      <Paper elevation={3} style={{ padding: 20, borderRadius: '15px' }}>
        <Box display="flex" justifyContent="space-between">
          <Typography className='fontstyleeb' variant="h5" gutterBottom>
            Section Details
          </Typography>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            sx={{ marginRight: 2, color: '#429bf5', borderColor: '#429bf5' }}
            className='buttonsd border-primary text-capitalize rounded-3'
          >
            Back
          </Button>
        </Box>
        <Box className='d-flex mb-4 mt-2'>
          <Box>
            <Typography className='fontstylee' sx={{fontSize: 12}}>Section Name</Typography>
            <Typography sx={{fontSize: 12}}>{sectionData.content_name}</Typography>
          </Box>
          <Box className='mx-4'>
            <Typography className='fontstylee' variant="subtitle1" sx={{fontSize: 12}}>
              Last Updated{' '}
              <Link onClick={toggleSidebar} className='link-underline' style={{ cursor: 'pointer', color: '#0175FF', fontSize:12 }}>
                Version History
              </Link>
            </Typography>
            <Typography variant="subtitle1" sx={{fontSize: 12}}>
              {sectionData.last_updated}
            </Typography>
          </Box>
        </Box>
        <Typography className='fontstylee' gutterBottom>
          Description
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{textAlign: "justify", fontSize: 12}}
          dangerouslySetInnerHTML={{ __html: sectionData.content_description }}
        />
      
        <Box alignItems="center" mb={2}>
          {sectionData.pdfAvailable ? ( 
            <>
              <Typography variant="h6" gutterBottom>
          Attachments
        </Typography>
              <img src={PDF} alt="PDF Icon" style={{ marginLeft: 10 }} />
              <Typography variant="body1">Cv.pdf 12 MB</Typography>
            </>
          ) : (
            <Typography variant="body1"></Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={() => navigate(-1)} className='border-primary text-capitalize rounded-3' sx={{ marginRight: 2, color: '#0D6EFD' }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(`/edit-section-details/${id}`, { state: { sectionName: sectionData.content_name, description: sectionData.content_description } })}
            sx={{ bgcolor: '#429bf5' }}
            className='bg-primary text-capitalize rounded-3'
          >
            Edit
          </Button>
        </Box>
      </Paper>
    </Box>
    <Drawer anchor="right" open={isSidebarOpen} onClose={toggleSidebar}>
      <Sidebar onClose={toggleSidebar} />
    </Drawer>
  </Container>
);
};

export default SectionDetails;
