import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Grid, InputLabel, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import Arrow from '../../assets/Images/Arrow.svg';
import LoginImg from '../../assets/Images/Loginimg.jpg';

// Styled components
const Root = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  justifyContent:'center'
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const Media = styled('img')({
  height: '100%',
});

const SpinnerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1300, // Ensure it is above other content
}));

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [loading, setLoading] = useState(false); // State for loading
  const { userid } = useParams();
  const { email } = location.state || {};

  // Separate state variables for password and confirm password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
  
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    if (!passwordRegex.test(password)) {
      setPasswordError('Password: min 8 chars, 1 uppercase, 1 lowercase, 1 digit, 1 special character');
      valid = false;
    } else {
      setPasswordError('');
    }
  
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      valid = false;
    } else {
      setConfirmPasswordError('');
    }
  
    if (valid) {
      setLoading(true); // Start loading
  
      axios.put('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/users/setpassword', {
        id: userid,
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response,'resetpassword');
          navigate('/');
        } else {
          console.error('Password update failed');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          navigate('/notfoundd');
        } else {
          console.error('Error updating password', error);
        }
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
    }
  };
  

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Prevent spacebar from being typed
    }
  };

  return (
    <Root>
      <Grid container component="main" display='flex' sx={{ height: '100vh', width: '100%' }}>
        <Grid item xs={false} sm={7} md={7} lg={8} className='hide-on-mobile'>
          <Media
            src={LoginImg}
            alt="Welcome"
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} className='position-relative'>
          <FormContainer sx={{ height: '100%' }}>
          <Box className='position-absolute top-0 start-0 d-flex mt-4  mb-4 arrowIcon'>
            <img src={Arrow} alt="Arrow Icon"  onClick={() => navigate('/')} className='mt-2'/>
          </Box>
            <div className='w-75 mt-5'>
              <Typography variant="h3">
                <strong>New Password</strong>
              </Typography>
              <Typography mb={2} className='loginsimplfyText'>
                Enter your new Log-In password
              </Typography>
              <Form noValidate onSubmit={handleSubmit}>
              <InputLabel>
                <Typography className ='labels'>
                Password
                </Typography>
              </InputLabel>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '15px',
                    '&:hover': {
                      borderColor: '#0D6EFD', // Outline color on hover
                    },
                    '&.Mui-focused': {
                      borderColor: '#0D6EFD', // Changes the border color to blue when focused
                      '& fieldset': {
                        borderColor: '#0D6EFD', // Ensure the outlined border changes to blue
                      },
                    },
                  },
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"} // Toggle password visibility
                size="medium"
                id="password"
                autoComplete="current-password"
                className='textfield'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length >= 6) {
                    setPasswordError(''); // Clear error if valid
                  }
                }}
                onKeyDown={handleKeyDown} // Prevent spaces
                error={Boolean(passwordError)}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
                
               <InputLabel>
                <Typography className ='labels'>
                Confirm Password
                </Typography>
              </InputLabel>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      '&:hover': {
                        borderColor: '#0D6EFD', // Outline color on hover
                      },
                      '&.Mui-focused': {
                        borderColor: '#0D6EFD', // Changes the border color to blue when focused
                        '& fieldset': {
                          borderColor: '#0D6EFD', // Ensure the outlined border changes to blue
                        },
                      },
                    },
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"} // Toggle confirm password visibility
                  id="confirmPassword"
                  autoComplete="current-password"
                  className='textfield'
                  value={confirmPassword}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (e.target.value === password) {
                      setConfirmPasswordError(''); // Clear error if passwords match
                    }
                  }}
                  error={Boolean(confirmPasswordError)}
                  helperText={confirmPasswordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <SubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  className='bg-primary text-capitalize rounded-4'
                  sx={{ padding: '10px 10px 10px 10px' }}
                >
                  Submit
                </SubmitButton>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  className='border-primary text-primary text-capitalize rounded-4'
                  sx={{ padding: '10px 10px 10px 10px' }}
                  onClick={() => navigate('/')}
                >
                  Cancel
                </Button>
              </Form>
            </div>
          </FormContainer>
        </Grid>
      </Grid>
      {loading && (
        <SpinnerContainer>
          <ClipLoader color="#0d6efd" size={50} />
        </SpinnerContainer>
      )}
    </Root>
  );
};

export default NewPassword;
