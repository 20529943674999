import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, Typography, Button, FormControl, TextField, MenuItem, Select ,IconButton,ListSubheader,Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import PDF from '../../assets/Images/PDF.svg'
import dayjs from 'dayjs';
import axios from 'axios'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { CalendarToday } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const statusOptions = [
    { value: 1, label: 'New' },
    { value: 4, label: 'Submitted' },
    { value: 3, label: 'On-Hold' },
    { value: 2, label: 'In-Progress' }
];
const submissionModes = [
    { value: 'Portal', label: 'Portal' },
    { value: 'Email', label: 'Email' },
    { value: 'Physical', label: 'Physical' }
];

export default function ExtrctedPdfDocument() {
    const [errors, setErrors] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',
        comments: '',
    });
    const [formData, setFormData] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',
        comments: '',
        filename: ''
    });
    const location = useLocation();
    const navigate = useNavigate();
    const { pdfText } = location.state;
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);


    const formatDateTime = (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm');
    };

    useEffect(() => {
        if (pdfText) {
            setFormData({
                firstReview: pdfText.details?.first_review === "" ?  "" : formatDateTime(pdfText.details?.first_review),
                rfpIssuer: pdfText.details?.rfp_issuer || '',
                rfpType: pdfText.details?.rfp_type || '',
                location: pdfText.details?.location || '',
                budgetRange: pdfText.details?.budget_range || '',
                expectedTimeline: pdfText.details?.expected_timeline || '',
                website: pdfText.details?.website || '',
                name: pdfText.details?.contact_name || '',
                email: pdfText.details?.email || '',
                conferenceDate: pdfText.details?.conference_date ===  "" ?  "" : formatDateTime(pdfText.details?.conference_date),
                conferenceInformation: pdfText.details?.conference_information || '',
                questionnaireDate: pdfText.details?.questionnaire_date === "" ?  "" : formatDateTime(pdfText.details?.questionnaire_date),
                status: pdfText.details?.status || '',
                submissionMode: pdfText.details?.submission_mode || '',
                submissionDate: pdfText.details?.submission_date === "" ?  "" : formatDateTime(pdfText.details?.submission_date),
                // remarks: pdfText.remarks?.remarks || '',
                comments: pdfText.comments?.comments || '',
                filename: pdfText.filename
            });
        }
    }, [pdfText]);

    useEffect(() => {
        const checkFormValidity = () => {
            const allFieldsValid = Object.values(errors).every(error => !error);
            const allFieldsFilled = Object.values(formData).every(value => {
                if (typeof value === 'string') {
                    return value.trim() !== '';
                } else if (typeof value === 'number') {
                    return value !== null && value !== undefined;
                } else {
                    return value !== null && value !== undefined;
                }
            });

            setIsFormValid(allFieldsValid && allFieldsFilled);
        };

        checkFormValidity();
    }, [formData, errors]);

   


    const [firstReviewDialogOpen, setfirstReviewDialogOpen] = useState(false);
    const [conferenceDialogOpen, setConferenceDialogOpen] = useState(false);
    const [QuestionnaireDialogOpen, setQuestionnaireDialogOpen] = useState(false);
    const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);




const [selectedConferenceDate, setSelectedConferenceDate] = useState(formData.conferenceDate ? dayjs(formData.conferenceDate) : null);
const [selectedQuestionnaireDate, setSelectedQuestionnaireDate] = useState(formData.questionnaireDate ? dayjs(formData.questionnaireDate) : null);
const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(formData.submissionDate ? dayjs(formData.submissionDate) : null);

    const validateField = (name, value) => {
        switch (name) {
            case 'email':
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address';
            case 'budgetRange':
                return value ? '' : 'Budget range is required';
            case 'website':
                return /^(https?:\/\/[^\s$.?#].[^\s]*)$/.test(value) ? '' : 'Invalid website URL';
            case 'conferenceInformation':
                return value ? '' : 'Conference Information is required';
            case 'submissionMode':
                return value ? '' : 'Submission mode is required';
            case 'status':
                return value ? '' : 'Status is required';
            case 'rfpIssuer':
                return value ? '' : 'RFP Issuer is required';
            case 'rfpType':
                return value ? '' : 'RFP Type is required';
            case 'location':
                return value ? '' : 'Location is required';
            case 'name':
                return value ? '' : 'Name is required';
            case 'firstReview':
            case 'expectedTimeline':
            case 'conferenceDate':
            case 'questionnaireDate':
            case 'submissionDate':
                return value ? '' : 'Date is required';
            case 'comments':
                return value ? '' : 'Comments are required';
            default:
                return '';
        }
    };
    
    
    const handleSave = async () => {
        setLoading(true)
        if (!isFormValid) return;
        let hasErrors = false;
        const newErrors = {};
    
        // Validate all fields
        for (const [key, value] of Object.entries(formData)) {
            const error = validateField(key, value);
            if (error) {
                newErrors[key] = error;
                hasErrors = true;
            }
        }
    
        setErrors(newErrors);
    
        if (hasErrors) return;  // Prevent form submission if there are errors
    
        // Proceed with form submission if no errors
        const storedData = JSON.parse(localStorage.getItem('userDetails'));
        const userId = storedData?.userDetails?.id;
    
        if (!userId) {
            console.error('User ID not found in local storage');
            navigate('/404');  // Navigate to 404 if userId is missing
            return;
        }
    
        const data = {
            details: {
                userid: userId,
                first_review: formatDateTime(formData?.firstReview),
                rfp_issuer: formData.rfpIssuer,
                rfp_type: formData.rfpType,
                location: formData.location,
                budget_range: formData.budgetRange,
                expected_timeline: formData.expectedTimeline,
                website: formData.website,
                contact_name: formData.name,
                contact_email: formData.email,
                conference_date: formatDateTime(formData?.conferenceDate),
                conference_information: formData.conferenceInformation,
                questionnaire_date: formatDateTime(formData?.questionnaireDate),
                submission_mode: formData.submissionMode,
                submission_date: formatDateTime(formData?.submissionDate),
                status: formData.status,
            },
            remarks: {
                userid: userId,
                remarks: "",
            },
            comments: {
                userid: userId,
                comments: formData.comments,
            },
            filename: formData.filename
        };

        console.log("req body--->", data)
    
        axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/addrfp', data)
            .then((response) => {
                console.log('Response:', response.data);
                navigate('/dashboard');  // Navigate to dashboard on success
                setLoading(false);
            })
            .catch((error) => {
                console.error('There was an error saving the RFP:', error);
                navigate('/404');  // Navigate to 404 if there is an error
            })
            .finally(() =>{
                setLoading(false);
              })
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        
        // Re-validate the field when the user changes it
        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));
    };
    

    const handleRfpCancel = () => {
        navigate('/dashboard');
    };

    const handleDateReviewAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'firstReview',
                    value: newValue.toISOString(),
                },
            });
        }
        setfirstReviewDialogOpen(false); // Close the dialog after confirming the date
    };

    const handleConferenceDateAccept = (newValue) => {
        if (newValue) {
          handleInputChange({
            target: {
              name: 'conferenceDate',
              value: newValue.toISOString(),
            },
          });
        }
        setConferenceDialogOpen(false); // Close the dialog after confirming the date
      };

      const handleQuestionnaireDateAccept = (newValue) => {
        if (newValue) {
          handleInputChange({
            target: {
              name: 'questionnaireDate',
              value: newValue.toISOString(),
            },
          });
        }
        setQuestionnaireDialogOpen(false); // Close the dialog after confirming the date
      };

      const handleSubmissionDateAccept = (newValue) => {
        if (newValue) {
          handleInputChange({
            target: {
              name:'submissionDate',
              value: newValue.toISOString(),
            },
          });
        }
        setSubmissionDialogOpen(false); // Close the dialog after confirming the date
      };


    // const renderSubheader = (rfpType) => {
    //     if (rfpType === 'Website Redesign' || rfpType === 'Cloud' || rfpType === 'ERP' || rfpType === 'Branding' || rfpType === 'SEO & Marketing') {
    //         return 'Solutioning';
    //     }
    //     if (rfpType === 'Resource') {
    //         return 'Staffing';
    //     }
    //     return '';
    // };

    return (
        <Container fluid className='ps-3 mt-2 rfpDtailsContainer' >
            <Card className='rounded-3' >
            <Box style={{ paddingBottom: '16px' }}>
                    <div  style={{ padding: '16px 0' }}>
                        <Row className="mb-2 align-items-center mx-1">
                            <Col xs={12} sm={6} md={6} >
                                <Box display="flex" alignItems="center" >
                                    <IconButton>
                                        <img src={PDF} alt="pdf" width="25px" height="25px" />
                                    </IconButton>
                                    {/* The text will align to the right of the image */}
                                    <span className='extractedPdfName' >{pdfText && pdfText.filename || 'MicrosoftRFP.pdf'}</span>
                                </Box>
                            </Col>
                            <Col xs={12} sm={6} md={6} className=" d-flex justify-content-end">
                                <Button className='border-primary text-primary text-capitalize rounded-2 '
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleRfpCancel}
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <form style={{ marginLeft: '15px', marginTop: '6px' }}>
                        <Container fluid>
                        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
                            <Box
                               sx={{
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                padding: '16px',
                                position: 'relative',
                                marginTop: "6px"
                            }}
                                className='mb-5'
                            >
                                <Typography
                                   sx={{
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '20px',
                                    background: '#fff',
                                    padding: '0 8px',
                                    fontSize: '14px'
                                }}
                                className='fontstyleeb'
                                >
                                    RFP Details
                                </Typography>
                                <Row className='pb-2'>
                                    <Col lg={3} md={6} sm={12}>
                                    <FormControl fullWidth margin="normal" className="mt-2">
                                            <Typography  className='fontstyleet' variant="h6" gutterBottom>
                                                First Review
                                            </Typography>
                                            <TextField
                                                name="firstReview"
                                                value={
                                                    formData.firstReview ? dayjs(formData.firstReview).format('YYYY-MM-DD HH:mm') : ''}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setfirstReviewDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.firstReview && (
                                                <Typography color="error">{errors.firstReview}</Typography>
                                            )}

                                            <Dialog
                                                open={firstReviewDialogOpen}
                                                onClose={() => setfirstReviewDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        overflow: 'auto',
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select First Review and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setfirstReviewDialogOpen(false)}
                                                        aria-label="close"
                                                        aria-hidden= {false}
                                                        aria-modal= {true}
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedConferenceDate}
                                                            minDate={dayjs("2024-04-01 12:00")}
                                                            onAccept={(newValue) => handleDateReviewAccept(newValue)}
                                                            onClose={() => setfirstReviewDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>
                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                    <Col lg={6} md={6} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography >RFP ISSUER</Typography>
                                            <TextField
                                                placeholder="Issuer Name"
                                                name="rfpIssuer"
                                                onChange={handleInputChange}
                                                value={formData.rfpIssuer}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                             {errors.rfpIssuer && <Typography color="error">{errors.rfpIssuer}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={12} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Rfp Type</Typography>
                                            <Select value={formData.rfpType} onChange={handleInputChange} name="rfpType" sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' }, '& .MuiSelect-icon': { top: '8px', color: 'blue' }, '& .MuiOutlinedInput-input': { paddingTop: '9px' } }}>
                                            <MenuItem className='subheader' value="Solutioning">Solutioning</MenuItem>
                                            <MenuItem className='subheader' value="Staffing">Staffing</MenuItem>
                                            </Select>
                                            {errors.rfpType && <Typography color="error">{errors.rfpType}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}  md={6} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Location</Typography>
                                            <TextField
                                                placeholder="Location"
                                                name="location"
                                                value={formData.location}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                            {errors.location && <Typography color="error">{errors.location}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}  md={6} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Budget Range</Typography>
                                            <TextField
                                                placeholder="Budget Range"
                                                name="budgetRange"
                                                value={formData.budgetRange}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                            {errors.budgetRange && <Typography color="error">{errors.budgetRange}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={12} sm={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Expected Timeline</Typography>
                                            <TextField
                                                placeholder="Expected Timeline"
                                                name="expectedTimeline"
                                                value={formData.expectedTimeline}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                             {errors.expectedTimeline && <Typography color="error">{errors.expectedTimeline}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={12} sm={12} xs={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Website</Typography>
                                            <TextField
                                                placeholder="Website"
                                                name="website"
                                                value={formData.website}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                             {errors.website && <Typography color="error">{errors.website}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                  
                                    position: 'relative'
                                }}
                                 className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Point of Contact Details
                                </Typography>
                                <Row>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Name</Typography>
                                            <TextField
                                                placeholder="Name"
                                                name="name"
                                                value={formData.name}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                               {errors.name && <Typography color="error">{errors.name}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Email</Typography>
                                            <TextField
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                              {errors.email && <Typography color="error">{errors.email}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                   
                                    position: 'relative'
                                }}
                                 className='mb-5'
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Submission Details
                                </Typography>
                                <Row className='pb-2'>
                                    <Col lg={3}  md={6} sm={12} xs={12}>
                                    <FormControl fullWidth margin="normal" className="mt-2">
                                            <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                Conference Date
                                            </Typography>
                                            <TextField
                                                name="conferenceDate"
                                                value={
                                                    formData.conferenceDate ? dayjs(formData.conferenceDate).format('YYYY-MM-DD HH:mm') : ''}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setConferenceDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.conferenceDate && (
                                                <Typography color="error">{errors.conferenceDate}</Typography>
                                            )}

                                            <Dialog
                                                open={conferenceDialogOpen}
                                                onClose={() => setConferenceDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        overflow: 'auto',
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select Conference Date and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setConferenceDialogOpen(false)}
                                                        aria-label="close"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedConferenceDate}
                                                            minDate={dayjs()}
                                                            onAccept={(newValue) => handleConferenceDateAccept(newValue)}

                                                            onClose={() => setConferenceDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>
                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Conference Information</Typography>
                                            <TextField
                                                // multiline
                                                name="conferenceInformation"
                                                value={formData.conferenceInformation}
                                                onChange={handleInputChange}
                                                placeholder="Conference Information"
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.conferenceInformation && (
                                                <Typography color="error" sx={{ fontSize: '0.75rem' }}>
                                                    {errors.conferenceInformation}
                                                </Typography>
                                            )}
                                        </FormControl>

                                    </Col>
                                    <Col lg={3}  md={6} sm={12} xs={12}>
                                    <FormControl fullWidth margin="normal" className='mt-2'>
                                            <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                Questionnaire Date
                                            </Typography>
                                            <TextField
                                                name="questionnaireDate"
                                                value={formData.questionnaireDate ? dayjs(formData.questionnaireDate).format('YYYY-MM-DD HH:mm') : ''}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setQuestionnaireDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px'
                                                    }
                                                }}
                                            />
                                            {errors.questionnaireDate && <Typography color="error">{errors.questionnaireDate}</Typography>}

                                            <Dialog
                                                open={QuestionnaireDialogOpen}
                                                onClose={() => setQuestionnaireDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed', // Fixed position
                                                        top: '50%', // Center vertically
                                                        left: '50%', // Center horizontally
                                                        transform: 'translate(-50%, -50%)', // Adjust for centering
                                                        overflow: 'auto', // Allow scrolling if content overflows
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select Questionnaire Date and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setQuestionnaireDialogOpen(false)}
                                                        aria-label="close"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500]
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedQuestionnaireDate}
                                                            minDate={dayjs()}
                                                            //   maxDate={dayjs().add(1, 'month')}
                                                            onAccept={(newValue) => handleQuestionnaireDateAccept(newValue)} // This will trigger when "OK" is clicked
                                                            onClose={() => setQuestionnaireDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>

                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}  md={6} sm={12} xs={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Status</Typography>
                                            <Select
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                name="status"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: 'blue' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {statusOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.status && <Typography color="error">{errors.status}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}  md={6} sm={12} xs={12}>
                                <FormControl fullWidth margin="normal">
                                            <Typography>Submission Mode</Typography>
                                            <Select
                                                value={formData.submissionMode}
                                                onChange={handleInputChange}
                                                name="submissionMode"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: 'blue' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {submissionModes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.submissionMode && <Typography color="error">{errors.submissionMode}</Typography>}
                                        </FormControl>
                                        </Col>
                                    <Col lg={3}  md={6} sm={12} xs={12}>
                                    <FormControl fullWidth margin="normal" className='mt-2'>
                                            <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                Submission Date
                                            </Typography>
                                            <TextField
                                                name="submissionDate"
                                                value={formData.submissionDate ? dayjs(formData.submissionDate).format('YYYY-MM-DD HH:mm') : ''}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setSubmissionDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px'
                                                    }
                                                }}
                                            />
                                            {errors.submissionDate && <Typography color="error">{errors.submissionDate}</Typography>}

                                            <Dialog
                                                open={submissionDialogOpen}
                                                onClose={() => setSubmissionDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed', // Fixed position
                                                        top: '50%', // Center vertically
                                                        left: '50%', // Center horizontally
                                                        transform: 'translate(-50%, -50%)', // Adjust for centering
                                                        overflow: 'auto', // Allow scrolling if content overflows
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select Submission Date and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setSubmissionDialogOpen(false)}
                                                        aria-label="close"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500]
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedSubmissionDate}
                                                            minDate={dayjs()}
                                                            //   maxDate={dayjs().add(1, 'month')}
                                                            onAccept={(newValue) => handleSubmissionDateAccept(newValue)} // This will trigger when "OK" is clicked
                                                            onClose={() => setSubmissionDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>

                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                 
                                    position: 'relative'
                                }}
                               
                            >
                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    Additional Details
                                </Typography>
                                <Row>
                                    {/* <Col lg={5}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Remarks</Typography>
                                            <TextField
                                                placeholder="Remarks"
                                                name="remarks"
                                                value={formData.remarks}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                                        {errors.remarks && <Typography color="error">{errors.remarks}</Typography>}
                                        </FormControl>
                                    </Col> */}
                                    <Col lg={5}  md={6} sm={12} xs={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Comments</Typography>
                                            <TextField
                                                placeholder="Comments"
                                                name="comments"
                                                value={formData.comments}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                                onChange={handleInputChange}
                                            />
                           {errors.comments && <Typography color="error">{errors.comments}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Row className="d-flex justify-content-center">
                                <Col lg={3}></Col>
                                <Col lg={9}>
                                    <Box display="flex" justifyContent="end">
                                        <Button variant="contained" sx={{ marginTop: 2}}  disabled={!isFormValid}  onClick={handleSave}  className={` text-capitalize rounded-3 ${isFormValid ? 'button-enabled' : 'button-disabled'}`} >Save</Button>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                </Box>
            </Card>
        </Container>
    );
}