import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Typography, Button, FormControl, TextField,  MenuItem, Select, Dialog, DialogTitle, DialogContent} from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styled } from '@mui/system';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import dayjs from 'dayjs';
import './AddnewRfp.css'
import { useSnackbar } from '../SnackbarContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { IconButton } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const patterns = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    url: /^(https?:\/\/[^\s/$.?#].[^\s]*)$/,
    name: /^[a-zA-Z\s]+$/,
    date: /^\d{4}-\d{2}-\d{2}$/,
    budget: /^[0-9]+(-[0-9]+)?$/,
};

const statusOptions = [
    { value: 1, label: 'New' },
    { value: 4, label: 'Submitted' },
    { value: 3, label: 'On Hold' },
    { value: 2, label: 'In Progress' }
];
const submissionModes = [
    { value: 'portal', label: 'Portal' },
    { value: 'email', label: 'Email' },
    { value: 'physical', label: 'Physical' }
];
export default function AddNewRpfPage() {
    const [formData, setFormData] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',

        comments: ''
    });
    const [errors, setErrors] = useState({
        firstReview: '',
        rfpIssuer: '',
        rfpType: '',
        location: '',
        budgetRange: '',
        expectedTimeline: '',
        website: '',
        name: '',
        email: '',
        conferenceDate: '',
        conferenceInformation: '',
        questionnaireDate: '',
        status: '',
        submissionMode: '',
        submissionDate: '',
        // remarks: '',
        //comments: ''
    });

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const formatDateTime = (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm');
    };



    const validateField = (name, value) => {
        switch (name) {
            case 'email':
                return patterns.email.test(value) ? '' : 'Invalid email address';
            case 'website':
                return patterns.url.test(value) ? '' : 'Invalid website URL';
            case 'name':
                return patterns.name.test(value) ? '' : 'Name can only contain letters and spaces';
            case 'budgetRange':
                return patterns.budget.test(value) ? '' : 'Budget Range can only contain numbers';
            case 'date':
                return patterns.date.test(value) ? '' : 'Invalid date format';
            case 'rfpIssuer':
                return patterns.name.test(value) ? '' : 'RFP Issuer can only contain letters and spaces';
            default:
                return value ? '' : 'This field is required';
        }
    };

    const [firstReviewDialogOpen, setfirstReviewDialogOpen] = useState(false);
    const [conferenceDialogOpen, setConferenceDialogOpen] = useState(false);
    const [QuestionnaireDialogOpen, setQuestionnaireDialogOpen] = useState(false);
    const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);

    const [selectedConferenceDate, setSelectedConferenceDate] = useState(formData.conferenceDate ? dayjs(formData.conferenceDate) : null);
    const [selectedQuestionnaireDate, setSelectedQuestionnaireDate] = useState(formData.questionnaireDate ? dayjs(formData.questionnaireDate) : null);
    const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(formData.submissionDate ? dayjs(formData.submissionDate) : null);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const error = validateField(name, value);
        if (name === 'firstReview' || name === 'expectedTimeline' || name === 'conferenceDate' || name === 'questionnaireDate' || name === 'submissionDate') {
            console.log(`${name}:`, value);
        }

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: error
        });
    };

    const [loading, setLoading] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleRfpCancel = () => {
        navigate('/dashboard');
    };

    const SpinnerContainer = styled(Box)(({ theme }) => ({
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1300,
    }));

    useEffect(() => {
        const checkFormValidity = () => {
            const allFieldsValid = Object.values(errors).every(error => !error);
            const allFieldsFilled = Object.values(formData).every(value => {
                if (typeof value === 'string') {
                    return value.trim() !== '';
                } else if (typeof value === 'number') {
                    return value !== null && value !== undefined;
                } else {
                    return value !== null && value !== undefined;
                }
            });

            setIsFormValid(allFieldsValid && allFieldsFilled);
        };

        checkFormValidity();
    }, [formData, errors]);



    const handleSave = async () => {
        if (!isFormValid) return; // Prevent save if validation fails

        let hasErrors = false;
        const newErrors = {};
        for (const [key, value] of Object.entries(formData)) {
            const error = validateField(key, value);
            if (error) {
                newErrors[key] = error;
                hasErrors = true;
            }
        }
        setErrors(newErrors);

        if (hasErrors) return;

        setLoading(true);
        try {
            // Simulate a save operation
            await new Promise((resolve) => setTimeout(resolve, 2000));
            showSnackbar('Details added successfully', 'success');
        } catch (error) {
            showSnackbar('Failed to add details', 'error');
        } finally {
            setLoading(false);
        }


        try {
            const storedData = JSON.parse(localStorage.getItem('userDetails'));
            const userId = storedData?.userDetails?.id;

            if (!userId) {
                throw new Error('User ID not found in local storage');
            }

            const data = {
                details: {
                    userid: userId,
                    first_review: formatDateTime(formData.firstReview),
                    rfp_issuer: formData.rfpIssuer,
                    rfp_type: formData.rfpType,
                    location: formData.location,
                    budget_range: formData.budgetRange,
                    expected_timeline: formData.expectedTimeline,
                    website: formData.website,
                    contact_name: formData.name,
                    contact_email: formData.email,
                    conference_date: formatDateTime(formData.conferenceDate),
                    conference_information: formData.conferenceInformation,
                    questionnaire_date: formatDateTime(formData.questionnaireDate),
                    submission_mode: formData.submissionMode,
                    submission_date: formatDateTime(formData.submissionDate),
                    status: formData.status,
                },
                remarks: {
                    userid: userId,
                    remarks: "",
                },
                comments: {
                    userid: userId,
                    comments: formData.comments,
                },
                filename: "",
            };

            console.log('data:', data);

            const response = await axios.post('https://pcn7tmrb2u.ap-south-1.awsapprunner.com/rfp_protal/dashboard/addrfp', data);
            console.log('Response:', response.data);
            navigate('/dashboard');
        } catch (error) {
            console.error('There was an error saving the RFP:', error);
            navigate('/404');
        }
    };

    const handleDateReviewAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'firstReview',
                    value: newValue.toISOString(),
                },
            });
        }
        setfirstReviewDialogOpen(false); // Close the dialog after confirming the date
    };

    const handleConferenceDateAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'conferenceDate',
                    value: newValue.toISOString(),
                },
            });
        }
        setConferenceDialogOpen(false); // Close the dialog after confirming the date
    };

    const handleQuestionnaireDateAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'questionnaireDate',
                    value: newValue.toISOString(),
                },
            });
        }
        setQuestionnaireDialogOpen(false); // Close the dialog after confirming the date
    };

    const handleSubmissionDateAccept = (newValue) => {
        if (newValue) {
            handleInputChange({
                target: {
                    name: 'submissionDate',
                    value: newValue.toISOString(),
                },
            });
        }
        setSubmissionDialogOpen(false); // Close the dialog after confirming the date
    };



    const handleReset = () => {
        setFormData({
            firstReview: '',
            rfpIssuer: '',
            rfpType: '',
            location: '',
            budgetRange: '',
            expectedTimeline: '',
            website: '',
            name: '',
            email: '',
            conferenceDate: '',
            conferenceInformation: '',
            questionnaireDate: '',
            status: '',
            submissionMode: '',
            submissionDate: '',
            // remarks: '',
            comments: ''
        });
        setErrors({
            firstReview: '',
            rfpIssuer: '',
            rfpType: '',
            location: '',
            budgetRange: '',
            expectedTimeline: '',
            website: '',
            name: '',
            email: '',
            conferenceDate: '',
            conferenceInformation: '',
            questionnaireDate: '',
            status: '',
            submissionMode: '',
            submissionDate: '',
            // remarks: '',
            //comments: ''
        });
    };

    return (
        <Container fluid className='ps-3 mt-2 rfpDtailsContainer ' >
            <Card className='rounded-3'>
                <Box style={{ paddingBottom: '16px' }}>
                    <div style={{ padding: '16px 0' }}>
                        <Row className="mb-2 align-items-center mx-1">
                            <Col xs={12} sm={6} md={6}>
                                <Typography className='fontstylee MicroSoftRFPDetails_heading' sx={{ width: 'auto', whiteSpace: 'nowrap' }}>
                                    Add RFP Details
                                </Typography>
                            </Col>
                            <Col xs={12} sm={6} md={6} className=" d-flex justify-content-end">
                                <Button className='border-primary text-primary text-capitalize rounded-2 '
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleRfpCancel}
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <form style={{ marginTop: '10px' }}>
                        <Container>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',

                                    position: 'relative'
                                }}
                                className='mb-5'
                            >

                                <Typography
                                className='fontstylee'
                                    sx={{
                                        position: 'absolute',
                                        top: '-12px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px',
                                    }}
                                >
                                    RFP Details
                                </Typography>
                                <Row className='pb-2'>
                                    {/* <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>First Review</Typography>
                                            <TextField type="date" name="firstReview" value={formData.firstReview} onChange={handleInputChange} placeholder="Select date" inputProps={{
                                                min: minDate,
                                                max: maxDate
                                            }} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.firstReview && <Typography color="error">{errors.firstReview}</Typography>}
                                        </FormControl>
                                    </Col> */}
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal" className="mt-2">
                                            <Typography  className='fontstyleet' variant="h6" gutterBottom>
                                                First Review
                                            </Typography>
                                            <TextField
                                                name="firstReview"
                                                value={
                                                    formData.firstReview ? dayjs(formData.firstReview).format('YYYY-MM-DD HH:mm') : ''}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setfirstReviewDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.firstReview && (
                                                <Typography color="error">{errors.firstReview}</Typography>
                                            )}

                                            <Dialog
                                                open={firstReviewDialogOpen}
                                                onClose={() => setfirstReviewDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        overflow: 'auto',
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select First Review and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setfirstReviewDialogOpen(false)}
                                                        aria-label="close"
                                                        aria-hidden= {false}
                                                        aria-modal= {true}
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedConferenceDate}
                                                            minDate={dayjs("2024-04-01 12:00")}
                                                            onAccept={handleDateReviewAccept}
                                                            onClose={() => setfirstReviewDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>
                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>RFP Issuer</Typography>
                                            <TextField autoComplete="current-rfpIssuer" placeholder="Issuer Name" name="rfpIssuer" onChange={handleInputChange} value={formData.rfpIssuer} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} />
                                            {errors.rfpIssuer && <Typography color="error">{errors.rfpIssuer}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>RFP Type</Typography>
                                            <Select value={formData.rfpType} IconComponent={KeyboardArrowDownIcon} onChange={handleInputChange} name="rfpType" sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' }, '& .MuiSelect-icon': { top: '8px', color: '#0175FF' }, '& .MuiOutlinedInput-input': { paddingTop: '9px' } }}>
                                                <MenuItem className='subheader' value="Solutioning">Solutioning</MenuItem>
                                                <MenuItem className='subheader' value="Staffing">Staffing</MenuItem>
                                            </Select>
                                            {errors.rfpType && <Typography color="error">{errors.rfpType}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Location</Typography>
                                            <TextField placeholder="Location" name="location" value={formData.location} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.location && <Typography color="error">{errors.location}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Budget Range</Typography>
                                            <TextField placeholder="Budget Range" name="budgetRange" value={formData.budgetRange} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.budgetRange && <Typography color="error">{errors.budgetRange}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Expected Timeline</Typography>
                                            <TextField placeholder="Expected Timeline" name="expectedTimeline" value={formData.expectedTimeline} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.expectedTimeline && <Typography color="error">{errors.expectedTimeline}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Website</Typography>
                                            <TextField placeholder="Website" name="website" value={formData.website} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.website && <Typography color="error">{errors.website}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    // marginBottom: '25px',
                                    position: 'relative'
                                }}
                                className='mb-5'
                            >
                                <Typography
                                className='fontstylee'
                                    sx={{
                                        position: 'absolute',
                                        top: '-12px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px',
                                    }}
                                >
                                    Point of Contact Details
                                </Typography>
                                <Row>
                                    <Col lg={4}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Name</Typography>
                                            <TextField placeholder="Name" name="name" value={formData.name} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.name && <Typography color="error">{errors.name}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={4}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Email</Typography>
                                            <TextField placeholder="Email" name="email" value={formData.email} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.email && <Typography color="error">{errors.email}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    marginBottom: '16px',
                                    position: 'relative'
                                }}
                                className='mb-5'
                            >
                                <Typography
                                 className='fontstylee'
                                    sx={{
                                        position: 'absolute',
                                        top: '-12px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px',
                                       
                                    }}
                                >
                                    Submission Details
                                </Typography>
                                <Row>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal" className="mt-2">
                                            <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                Conference Date
                                            </Typography>
                                            <TextField
                                                name="conferenceDate"
                                                value={
                                                    formData.conferenceDate ? dayjs(formData.conferenceDate).format('YYYY-MM-DD HH:mm') : ''}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setConferenceDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.conferenceDate && (
                                                <Typography color="error">{errors.conferenceDate}</Typography>
                                            )}

                                            <Dialog
                                                open={conferenceDialogOpen}
                                                onClose={() => setConferenceDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        overflow: 'auto',
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select Conference Date and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setConferenceDialogOpen(false)}
                                                        aria-label="close"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500],
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedConferenceDate}
                                                            minDate={dayjs()}
                                                            onAccept={handleConferenceDateAccept}

                                                            onClose={() => setConferenceDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>
                                            </Dialog>
                                        </FormControl>
                                    </Col>


                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Conference Information</Typography>
                                            <TextField
                                                // multiline
                                                name="conferenceInformation"
                                                value={formData.conferenceInformation}
                                                onChange={handleInputChange}
                                                placeholder="Conference Information"
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                            {errors.conferenceInformation && (
                                                <Typography color="error" sx={{ fontSize: '0.75rem' }}>
                                                    {errors.conferenceInformation}
                                                </Typography>
                                            )}
                                        </FormControl>

                                    </Col>


                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal" className='mt-2'>
                                            <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                Questionnaire Date
                                            </Typography>
                                            <TextField
                                                name="questionnaireDate"
                                                value={formData.questionnaireDate ? dayjs(formData.questionnaireDate).format('YYYY-MM-DD HH:mm') : ''}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setQuestionnaireDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px'
                                                    }
                                                }}
                                            />
                                            {errors.questionnaireDate && <Typography color="error">{errors.questionnaireDate}</Typography>}

                                            <Dialog
                                                open={QuestionnaireDialogOpen}
                                                onClose={() => setQuestionnaireDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed', // Fixed position
                                                        top: '50%', // Center vertically
                                                        left: '50%', // Center horizontally
                                                        transform: 'translate(-50%, -50%)', // Adjust for centering
                                                        overflow: 'auto', // Allow scrolling if content overflows
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select Questionnaire Date and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setQuestionnaireDialogOpen(false)}
                                                        aria-label="close"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500]
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedQuestionnaireDate}
                                                            minDate={dayjs()}
                                                            //   maxDate={dayjs().add(1, 'month')}
                                                            onAccept={handleQuestionnaireDateAccept} // This will trigger when "OK" is clicked
                                                            onClose={() => setQuestionnaireDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>

                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Status</Typography>
                                            <Select
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                IconComponent={KeyboardArrowDownIcon}
                                                name="status"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: '#0175FF' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {statusOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.status && <Typography color="error">{errors.status}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Submission Mode</Typography>
                                            <Select
                                                value={formData.submissionMode}
                                                onChange={handleInputChange}
                                                name="submissionMode"
                                                IconComponent={KeyboardArrowDownIcon}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px', height: '45px' },
                                                    '& .MuiSelect-icon': { top: '8px', color: '#0175FF' },
                                                    '& .MuiOutlinedInput-input': { paddingTop: '9px' }
                                                }}
                                            >
                                                {submissionModes.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.submissionMode && <Typography color="error">{errors.submissionMode}</Typography>}
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <FormControl fullWidth margin="normal" className='mt-2'>
                                            <Typography variant="h6" className='fontstyleet' gutterBottom>
                                                Submission Date
                                            </Typography>
                                            <TextField
                                                name="submissionDate"
                                                value={formData.submissionDate ? dayjs(formData.submissionDate).format('YYYY-MM-DD HH:mm') : ''}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Select date"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => setSubmissionDialogOpen(true)}
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            <CalendarMonthIcon sx={{color:'#0175FF'}} />
                                                        </IconButton>
                                                    ),
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '40px'
                                                    }
                                                }}
                                            />
                                            {errors.submissionDate && <Typography color="error">{errors.submissionDate}</Typography>}

                                            <Dialog
                                                open={submissionDialogOpen}
                                                onClose={() => setSubmissionDialogOpen(false)}
                                                maxWidth="xs"
                                                fullWidth
                                                PaperProps={{
                                                    style: {
                                                        height: '85%',
                                                        width: '100%',
                                                        borderRadius: '12px',
                                                        position: 'fixed', // Fixed position
                                                        top: '50%', // Center vertically
                                                        left: '50%', // Center horizontally
                                                        transform: 'translate(-50%, -50%)', // Adjust for centering
                                                        overflow: 'auto', // Allow scrolling if content overflows
                                                    },
                                                }}
                                            >
                                                <DialogTitle>
                                                    Select Submission Date and Time
                                                    <IconButton
                                                        edge="end"
                                                        color="inherit"
                                                        onClick={() => setSubmissionDialogOpen(false)}
                                                        aria-label="close"
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 9,
                                                            top: 8,
                                                            color: (theme) => theme.palette.grey[500]
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </DialogTitle>
                                                <DialogContent>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <StaticDateTimePicker
                                                            value={selectedSubmissionDate}
                                                            minDate={dayjs()}
                                                            //   maxDate={dayjs().add(1, 'month')}
                                                            onAccept={handleSubmissionDateAccept} // This will trigger when "OK" is clicked
                                                            onClose={() => setSubmissionDialogOpen(false)} // Closes on cancel or outside click
                                                            ampm={false}
                                                        />
                                                    </LocalizationProvider>
                                                </DialogContent>

                                            </Dialog>
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    padding: '16px',
                                    marginBottom: '16px',
                                    position: 'relative'
                                }}
                            >
                                <Typography
                                 className='fontstylee'
                                    sx={{
                                        position: 'absolute',
                                        top: '-10px',
                                        left: '20px',
                                        background: '#fff',
                                        padding: '0 8px',
                                        fontSize: '16px',
                                       
                                    }}
                                >
                                    Additional Details
                                </Typography>

                                <Row>
                                    {/* <Col lg={3}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography>Remarks</Typography>
                                            <TextField placeholder="Remarks" name="remarks" value={formData.remarks} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.remarks && <Typography color="error">{errors.remarks}</Typography>}
                                        </FormControl>
                                    </Col> */}
                                    <Col lg={3} md={6} sm={12} xs={12}>
                                        <FormControl fullWidth margin="normal">
                                            <Typography className='fontstyleet'>Comments</Typography>
                                            <TextField placeholder="Comments" name="comments" value={formData.comments} sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiInputBase-root': { height: '40px' } }} onChange={handleInputChange} />
                                            {errors.comments && <Typography color="error">{errors.comments}</Typography>}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Box>
                            <Row className="d-flex justify-content-center">
                                <Col lg={3}></Col>
                                <Col lg={9}>
                                    <Box display="flex" justifyContent="end" mt={2} mx={1}>
                                        <Button variant="outlined" color="secondary" onClick={handleReset} className='mx-2 border border-primary text-primary rounded-2'>Reset</Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                            className={` text-capitalize rounded-3 ${isFormValid ? 'button-enabled' : 'button-disabled'}`}
                                            disabled={!isFormValid}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Col>
                            </Row>
                        </Container>
                    </form>
                </Box>
            </Card>
            {loading && (
                <SpinnerContainer>
                    <ClipLoader size={50} color="#123abc" loading={loading} />
                </SpinnerContainer>
            )}
        </Container>
    );
}